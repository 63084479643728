import { FC, useState } from 'react';

import { AddRemovePopover } from './AddRemovePopover';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitPopper } from 'components/JitPopper/JitPopper';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant, IFinding } from 'types/interfaces';

interface FeedbackPopperProps {
  finding: IFinding;
}

export const AddRemoveButton: FC<FeedbackPopperProps> = ({ finding }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onClose = () => {
    setIsDialogOpen(false);
  };
  return (
    <JitPopper
      content={<AddRemovePopover finding={finding} onClose={onClose} />}
      isWithArrow={false}
      onClose={onClose}
      open={isDialogOpen}
      placement='right-start'
      shadowVariant='none'
      withBackdrop
    >
      <div data-testid='AddRemoveButton'>
        <JitActionButton
          actionButton={{
            label: 'pages.findings.findingDetails.priority.addRemoveFactors.buttonText',
            handleClick: () => setIsDialogOpen(!isDialogOpen),
            variant: ActionButtonVariant.SECONDARY,
            noHover: true,
            textProps: {
              color: colors.blue02,
            },
          }}
        />
      </div>
    </JitPopper>
  );
};
