import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ChooseGitlabProjects } from '../StepComponents/ChooseGitlabProjects/ChooseGitlabProjects';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { useTenantContext } from 'context/TenantContext';
import { IGroup, IProject, allProjectCoverageType, selectedProjectCoverageType, useGitlabService } from 'services/GitlabService/useGitlabService';

interface Props {
  selectedGroup?: IGroup;
  handleClose: () => void;
}

export const useChooseGitlabProjectsStep = ({ selectedGroup, handleClose }: Props): IGitlabIntegrationWizardStep => {
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [isUpdateInstallationLoading, setIsUpdateInstallationLoading] = useState(false);
  const [chooseProjectCompleted, setChooseProjectCompleted] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<IProject[]>([]);
  const [isStepCompleted, setIsStepCompleted] = useState(false);

  const { initialOnboardingCompleted, SCMIntegrationCompleted } = useTenantContext();
  const { uiShowSecurityChampionQuickGuide } = useFlags();

  const { updateInstallationProjects } = useGitlabService();

  const status = useMemo(() => {
    if (isStepCompleted) return IGitlabIntegrationWizardStepStatus.COMPLETE;

    return IGitlabIntegrationWizardStepStatus.ACTIVE;
  }, [isStepCompleted]);

  const completeTask = useCallback(() => {
    setIsUpdateInstallationLoading(false);
    setIsStepCompleted(true);
    handleClose();
  }, [handleClose]);

  const onContinueButtonClick = useCallback(async () => {
    if (!selectedGroup?.id) return;

    const reqBody = isAllSelected
      ? {
        project_coverage_type: allProjectCoverageType,
      }
      : {
        project_coverage_type: selectedProjectCoverageType,
        projects: selectedProjects.map(({ id, name }) => ({ project_id: id,
          project_name: name })),
      };

    setIsUpdateInstallationLoading(true);
    await updateInstallationProjects(selectedGroup?.id, reqBody);
    if (!uiShowSecurityChampionQuickGuide) {
      completeTask();
    } else {
      setChooseProjectCompleted(true);
    }
  }, [selectedGroup?.id, isAllSelected, selectedProjects, updateInstallationProjects, uiShowSecurityChampionQuickGuide, completeTask]);

  useEffect(() => {
    if (chooseProjectCompleted && initialOnboardingCompleted && SCMIntegrationCompleted) {
      completeTask();
    }
  }, [chooseProjectCompleted, initialOnboardingCompleted, SCMIntegrationCompleted, handleClose, completeTask]);

  const chooseGitlabProjectsStep = useMemo(() => ({
    title: 'GitlabIntegrationWizard.chooseGitlabProjects.tabText',
    component: () => (
      <ChooseGitlabProjects
        isAllSelected={isAllSelected}
        isUpdateInstallationLoading={isUpdateInstallationLoading}
        onContinueButtonClick={onContinueButtonClick}
        selectedGroup={selectedGroup}
        selectedProjects={selectedProjects}
        setIsAllSelected={setIsAllSelected}
        setSelectedProjects={setSelectedProjects}
      />
    ),
    status,
  }), [isAllSelected, isUpdateInstallationLoading, onContinueButtonClick, selectedGroup, selectedProjects, status]);

  return chooseGitlabProjectsStep;
};
