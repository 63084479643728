import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import React, { FC } from 'react';

import styles from './ConfirmDialog.module.scss';

import { Cross } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IDictionary } from 'types/interfaces';
import { ActionButton } from 'types/interfaces/ActionButton/ActionButton';

interface Props {
  title: string
  message: string
  subMessage?: string
  messageParams?: IDictionary<string | number | undefined | false | null>
  isOpen: boolean
  actionButtons?: ActionButton[]
  onClose: ((event: React.MouseEvent<HTMLButtonElement>) => void) | (() => void)
}

const paperStyle = {
  backgroundColor: colors.cards,
  boxShadow: 'none',
  border: `1px solid ${colors.darkGray}`,
  borderRadius: 8,
  width: 456,
  height: 239,
  backgroundImage: 'none',
  overflow: 'auto',
};

export const ConfirmDialog: FC<Props> = ({ title, message, messageParams, subMessage, isOpen, onClose, actionButtons }) => (
  <Dialog
    aria-describedby='alert-dialog-description'
    aria-labelledby='alert-dialog-title'
    data-testid='confirmDialog'
    maxWidth='xl'
    onClose={onClose}
    open={isOpen}
    PaperProps={{ style: paperStyle }}
  >
    <DialogTitle className={styles.title} id='alert-dialog-title'>
      <JitText bold size='m' text={title} />

      <JitButton data-testid={`confirmation-dialog-${title}`} onClick={onClose}>
        <JitIcon color={colors.lightBlue} icon={Cross} size={25} />
      </JitButton>

    </DialogTitle>

    <Divider className={styles.divider} />

    <DialogContent>
      <JitText bold params={messageParams} size='m' style={{ marginBottom: 15 }} text={message} />

      <JitText size='s' text={subMessage} />
    </DialogContent>

    {actionButtons && (
    <div className={styles.buttons}>
      <div className={styles.actionButtons}>
        {actionButtons.map((actionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
      </div>
    </div>
    )}
  </Dialog>
);
