import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { useFormatAndExtendFilters, useSyncFiltersWithQueryParams } from '.';

import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { buildGetFindingsRequestParams } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import { fetchFilterOptions } from 'services/FindingsService';

export const useInitFilters = () => {
  const { filters, setFilters, currentActiveColumn, setHasFetchedFilters, getSavedFilters } = useFindingsContext();
  const { relevantQueryParams, getUpdateFiltersWithInitialQueryParams } = useSyncFiltersWithQueryParams(filters);

  const { formatAndExtendFilters } = useFormatAndExtendFilters();

  const getFilterOptions = useCallback(async () => {
    const formattedFilterParams = buildGetFindingsRequestParams({ currentActiveColumn });

    const fetchedOptions = await fetchFilterOptions(formattedFilterParams);

    if (!fetchedOptions) {
      console.error('Error while getting filter options');
    }
    return fetchedOptions;
  }, [currentActiveColumn]);

  const initFilters = useCallback(async () => {
    const serverFilterOptions = await getFilterOptions();

    if (serverFilterOptions) {
      let updatedFilters = formatAndExtendFilters(filters, serverFilterOptions);
      if (!isEmpty(relevantQueryParams)) {
        updatedFilters = getUpdateFiltersWithInitialQueryParams(updatedFilters);
      }
      setFilters(updatedFilters);
    }

    setHasFetchedFilters(true);
    getSavedFilters(true);
  }, [getFilterOptions, setHasFetchedFilters, getSavedFilters, formatAndExtendFilters, filters, relevantQueryParams, setFilters, getUpdateFiltersWithInitialQueryParams]);

  return { initFilters };
};
