import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { SourceControl } from 'assets';
import { useIntegrationsContext } from 'context/IntegrationsContext/IntegrationsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { getIntegrationTemplateVendor } from 'pages/IntegrationsPage/utils/getIntegrationTemplateVendor';
import { AssetType, Vendor } from 'types/enums';
import { ISvg } from 'types/interfaces';
import { IIntegration, IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { iconsMap } from 'utils/constants/iconsMap';

const getSCMIcon = (scmVendor?: string): React.FC<ISvg> => {
  if (scmVendor === Vendor.Github) {
    return iconsMap.github.icon;
  }

  return SourceControl;
};

const getVendorAndIconForAssetType = (assetType: AssetType, scmVendor?: string): { vendor: Vendor | undefined, icon: React.FC<ISvg> | null } => {
  switch (assetType) {
    case AssetType.AWS_ACCOUNT:
      return {
        vendor: Vendor.AWS,
        icon: iconsMap.aws.icon,
      };
    case AssetType.GCP_ACCOUNT:
      return {
        vendor: Vendor.GCP,
        icon: iconsMap.gcp.icon,
      };
    case AssetType.AZURE_ACCOUNT:
      return {
        vendor: Vendor.AZURE,
        icon: iconsMap.azure.icon,
      };
    case AssetType.AWS_ORG:
      return {
        vendor: Vendor.AWS,
        icon: iconsMap.aws.icon,
      };
    case AssetType.REPO:
      return {
        vendor: scmVendor as Vendor,
        icon: getSCMIcon(scmVendor),
      };
    case AssetType.ORG:
      return {
        vendor: scmVendor as Vendor,
        icon: getSCMIcon(scmVendor),
      };
    case AssetType.APPLICATION:
      return {
        vendor: Vendor.Domain,
        icon: null,
      };
    case AssetType.API:
      return {
        vendor: Vendor.ZAP_API,
        icon: iconsMap.api.icon,
      };
    case AssetType.WEB:
      return {
        vendor: Vendor.ZAP_WEB,
        icon: iconsMap.web.icon,
      };
    default:
      return {
        vendor: undefined,
        icon: null,
      };
  }
};

const getIconComponent = (iconUrl: string): React.FC<ISvg> => {
  const IconComponent: React.FC<ISvg> = (props) => (
    <svg {...props}>
      <image height='100%' href={iconUrl} width='100%' />
    </svg>
  );
  return IconComponent;
};

const getIconFromThirdPartyIntegrationForASsetType = (integrations: IIntegration[], assetType: AssetType, scmVendor?: string): React.FC<ISvg> | undefined => {
  const { vendor } = getVendorAndIconForAssetType(assetType, scmVendor);
  if (!vendor) {
    return undefined;
  }

  const integration = integrations.find((currIntegration) => getIntegrationTemplateVendor(currIntegration.vendor) === vendor);
  const isIntegrationProvidedByJit = !integration || integration.provider === IntegrationProvider.JIT;
  if (isIntegrationProvidedByJit) {
    return undefined;
  }

  return getIconComponent(integration.display.icon);
};

export const useAssetTypeToVendor = () => {
  const { currentScmVendor } = useTenantContext();
  const { integrations, isLoading } = useIntegrationsContext();

  const getIconByAssetType = useCallback((assetType: AssetType) => {
    if (isLoading) {
      return null;
    }
    if (integrations && !isEmpty(integrations)) {
      const iconFromIntegration = getIconFromThirdPartyIntegrationForASsetType(integrations, assetType, currentScmVendor);

      if (iconFromIntegration) {
        return iconFromIntegration;
      }
    }

    const { icon } = getVendorAndIconForAssetType(assetType, currentScmVendor);
    return icon;
  }, [currentScmVendor, integrations, isLoading]);

  const getVendorByAssetType = useCallback((assetType: AssetType) => {
    if (isLoading) {
      return undefined;
    }

    const { vendor } = getVendorAndIconForAssetType(assetType, currentScmVendor);
    return vendor;
  }, [currentScmVendor, isLoading]);

  return { getIconByAssetType,
    getVendorByAssetType };
};
