import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useGetPlanItemsTemplatesBySlugs } from 'context/PlanTemplatesContext/hooks';
import { usePlanTemplatesContext } from 'context/PlanTemplatesContext/PlanTemplatesContext';
import { ResourcePlanItemsStatusesTableData } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/ResourcePlanItemsStatusesPage';
import { fetchFindingsCount } from 'services/FindingsService';
import { usePlanService } from 'services/PlanService/usePlanService';
import { FindingStatus } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IGroupByFindingsCount } from 'types/interfaces/Findings/IFindingsCount';
import { PlanItemAssetStatus, Status } from 'types/interfaces/PlanItemAssetStatus/IPlanItemAssetStatus';

interface IQueryParams {
  group_by: string;
  filters: {
    asset_name: string;
    resolution: FindingStatus;
    ignored: boolean[];
    team?: string;
  };
}

export const useGetTableData = (resourceId: string, resourceName: string, teamName?: string) => {
  const { isLoading: isLoadingPlanItemTemplates, fetchPlanItemTemplates, fetchControls, planItemsTemplates } = usePlanTemplatesContext();
  const { getPlanStatusesForAsset } = usePlanService();

  const { getPlanItemsTemplatesBySlugs } = useGetPlanItemsTemplatesBySlugs();

  useEffect(() => {
    if (isEmpty(planItemsTemplates) && !isLoadingPlanItemTemplates) {
      fetchPlanItemTemplates();
      fetchControls();
    }
  }, [fetchControls, fetchPlanItemTemplates, isLoadingPlanItemTemplates, planItemsTemplates]);

  const { data: planItems, isLoading: isLoadingPlanItems } = useQuery(
    [Queries.ResourceFactors, resourceId],
    () => getPlanStatusesForAsset(resourceId),
  );

  const findingsCountParams: IQueryParams = useMemo(() => {
    const params: IQueryParams = {
      group_by: 'plan_item',
      filters: {
        asset_name: resourceName,
        resolution: FindingStatus.OPEN,
        ignored: [false],
      },
    };
    if (teamName) {
      params.filters.team = teamName;
    }
    return params;
  }, [resourceName, teamName]);

  const { data: planItemsFindingCount, isLoading: isLoadingPlanItemsFindingCount } = useQuery(
    [Queries.PlanItemsFindingsCount, resourceName, teamName],
    () => fetchFindingsCount(findingsCountParams),
  );

  const findingsCounts = useMemo(() => (planItemsFindingCount as IGroupByFindingsCount[] || []).reduce((acc, item) => {
    acc[item.key] = item.count;
    return acc;
  }, {} as Record<string, number>), [planItemsFindingCount]);

  const tableData = useMemo(() => {
    let data: ResourcePlanItemsStatusesTableData[] = [];
    if (!planItems) return data;
    const planItemDisplayNames = getPlanItemsTemplatesBySlugs(planItems.map((planItem) => planItem.itemSlug));
    if (!isLoadingPlanItems) {
      data = planItems.map((planItem: PlanItemAssetStatus) => {
        const findingsCount = (planItemsFindingCount as IGroupByFindingsCount[])?.find((finding) => finding.key === planItem.itemSlug)?.count || 0;
        return ({
          slug: planItem.itemSlug,
          name: planItemDisplayNames.find((planItemDisplayName) => planItemDisplayName.slug === planItem.itemSlug)?.name || '',
          passed: planItem.status === Status.SUCCESS,
          findingsCount,
        });
      });
    }

    return data;
  }, [getPlanItemsTemplatesBySlugs, planItems, isLoadingPlanItems, planItemsFindingCount]);

  const totalFindingsCount = useMemo(
    () => (planItemsFindingCount as IGroupByFindingsCount[])?.reduce((acc, finding) => acc + finding.count, 0),
    [planItemsFindingCount],
  );
  const isLoading = useMemo(
    () => isLoadingPlanItems || isLoadingPlanItemsFindingCount || isLoadingPlanItemTemplates,
    [isLoadingPlanItems, isLoadingPlanItemsFindingCount, isLoadingPlanItemTemplates],
  );
  return {
    isLoading,
    tableData: tableData as ResourcePlanItemsStatusesTableData[] || [],
    totalFindingsCount,
    findingsCounts: findingsCounts as Record<string, number> || {},
  };
};
