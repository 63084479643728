import { FC, ReactNode } from 'react';

import styles from './TableGradient.module.scss';

interface TableGradientProps {
  children: ReactNode;
}

export const TableGradient: FC<TableGradientProps> = ({ children }) => (
  <div className={styles.root} data-testid='table-gradient'>
    <div className={styles.gradient}>
      {children}
    </div>
  </div>
);
