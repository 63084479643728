import { useMemo } from 'react';
import { Accessor } from 'react-table';

import { TextCell } from 'components/JitTable/templates';
import { i18n } from 'locale/i18n';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import { PriorityFactorsCell } from 'pages/Resources/ResourcesPage/component/ResourcesTable/TableCells/PriorityFactorsCell/PriorityFactorsCell';
import { ResourceNameCell } from 'pages/Resources/ResourcesPage/component/ResourcesTable/TableCells/ResorurceNameCell/ResourceNameCell';
import { IAsset, ITableCell } from 'types/interfaces';
import { TableColumns } from 'types/types/TableColumns';

interface IColumnHeaders {
  name: string;
  type: string;
  score: string;
  team: string;
  priorityFactors: string;
  findingsByPriorityScore: string;
}

export const useGetTableColumns = () => {
  const { t } = i18n;
  const columnHeaders: IColumnHeaders = useMemo(() => t('pages.risks.resources.resourcesPage.table.columnHeaders', { returnObjects: true }), [t]);

  const getColumnsData = (): TableColumns<object> => ({
    columns: [
      {
        Header: columnHeaders.name,
        Cell: ResourceNameCell,
        width: '30%',
      },
      {
        Header: columnHeaders.type,
        accessor: ((asset: IAsset) => `assetType.${asset.asset_type}`) as Accessor<object>,
        Cell: TextCell,
        width: '16%',
      },
      {
        Header: columnHeaders.score,
        accessor: 'priority_score',
        Cell: ({ cell: { value } }: ITableCell) => (
          <PriorityBox priorityScore={value} />
        ),
        width: '10%',
      },
      {
        Header: columnHeaders.team,
        accessor: ((asset: IAsset) => asset.tags.filter((tag) => tag.name === 'team').map((tag) => tag.value).join(', ')) as Accessor<object>,
        Cell: TextCell,
        width: '15%',
      },
      {
        Header: columnHeaders.priorityFactors,
        accessor: 'priority_factors',
        Cell: PriorityFactorsCell,
        width: '34%',
      },
    ],
  });

  return useMemo(getColumnsData, [columnHeaders]);
};
