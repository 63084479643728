import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ResourcesDashboardTitle.module.scss';

import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';

interface Props {
  resourcesCount: number;
}

export const ResourcesDashboardTitle: FC<Props> = ({ resourcesCount }) => {
  const navigate = useNavigate();
  const { scmInstallations } = useTenantContext();
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;

  const onClick = () => {
    navigate(`/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`);
  };
  const orgName = scmInstallations?.[0]?.owner;

  return (
    <div className={styles.titleWrapper}>
      {orgName
        ? <JitText data-testid='org-title' params={{ orgName }} size='xxxxl' text='pages.resources.resourcesDashboard.orgTitle' />
        : <JitText data-testid='org-title' size='xxxxl' text='pages.resources.resourcesDashboard.emptyOrgTitle' />}

      <JitText
        color={colors.lightBlue}
        data-testid='resources-count'
        onClick={onClick}
        params={{ resourcesCount }}
        size='l'
        text='pages.resources.resourcesDashboard.resourcesCount'
      />
    </div>
  );
};
