import { FC } from 'react';

import styles from './ResourcesStatistics.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import { FindingsBreakdown } from 'pages/Resources/components/FindingsBreakdown/FindingsBreakdown';
import colors from 'themes/colors.module.scss';

interface Props {
  score: number;
  findingsBreakdown: {
    high: number;
    medium: number;
    low: number;
  };
}

export const ResourcesStatistics: FC<Props> = ({ score, findingsBreakdown }) => (

  <div className={styles.wrapper} data-testid='resources-statistics'>
    <div className={styles.sectionWrapper}>
      <JitText bold color={colors.lightGray} size='xs' text='pages.resources.resourcesDashboard.averageScore' />

      <PriorityBox priorityScore={score} withBorder={false} />
    </div>

    <div className={styles.sectionWrapper}>
      <JitText bold color={colors.lightGray} size='xs' text='pages.resources.resourcesDashboard.findingsByCriticality' />

      <FindingsBreakdown {...findingsBreakdown} />
    </div>
  </div>
);
