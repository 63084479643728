// PlanStatusItemTable.tsx
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetColumns } from './components/useGetColumns';
import styles from './PlanStatusItemTable.module.scss';

import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { constants } from 'globalConstants';
import { ResourcePlanItemsStatusesTableData } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/ResourcePlanItemsStatusesPage';

interface PlanStatusItemTableProps {
  teamId: string;
  resourceId: string;
  resourceName: string;
  tableData: ResourcePlanItemsStatusesTableData[];
  findingsCounts: Record<string, number>;
  isLoading: boolean;
}

export const PlanStatusItemTable: FC<PlanStatusItemTableProps> = ({
  resourceName,
  tableData,
  teamId,
  resourceId,
  findingsCounts,
  isLoading,
}) => {
  const columns = useGetColumns(resourceName, tableData.length, findingsCounts);
  const nagivate = useNavigate();
  const tableInstance = useGetTableInstance(columns, tableData);
  const {
    teamsPortal: { BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE, PORTAL_TEAMS, PORTAL_RESOURCES, PORTAL_PLAN_ITEMS },
  } = constants.routes;

  const getDynamicRowHoverStyle = (row: ResourcePlanItemsStatusesTableData) => (row.passed ? { borderColor: 'transparent' } : { cursor: 'pointer' });
  const onRowClick = (row: ResourcePlanItemsStatusesTableData) => {
    if (!row.passed) nagivate(`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${teamId}/${PORTAL_RESOURCES}/${resourceId}/${PORTAL_PLAN_ITEMS}/${row.slug}`);
  };
  return (
    <div className={styles.tableWrapper}>
      <JitTable
        cellPadding='16px 0px 16px 40px'
        cellsNumber={columns.length}
        emptyTableSubtext='pages.teamsPortal.resourcePage.noPlanItemStatuses.description'
        emptyTableText='pages.teamsPortal.resourcePage.noPlanItemStatuses.title'
        getDynamicRowHoverStyle={getDynamicRowHoverStyle}
        headerCellPadding='7px 0px 7px 40px'
        isLoading={isLoading}
        jittyIcon={null}
        onSelectRow={onRowClick}
        shouldDisplayWithPaper={false}
        showPagination={false}
        tableDesign='light'
        tableInstance={tableInstance}
      />
    </div>
  );
};

