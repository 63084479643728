import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { FC, useMemo } from 'react';

import { FindingsFilterSaveFindingDialogActions } from './components/SaveFindingFilterDialogActions/SaveFindingFilterDialogActions';

import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { inputDefaultProps, paperStyle } from 'pages/FindingsPage/utils/dialogueStyle';

interface Props {
  handleClose: () => void;
  handleSave: () => void;
  filterName: string;
  filterDescription: string;
  filterShouldNotify: boolean;
  setFilterName: (filterName: string) => void;
  setFilterDescription: (filterDescription: string) => void;
  setFilterShouldNotify: (filterShouldNotify: boolean) => void;
  open: boolean;
}

export const FindingsFiltersSaveDialog: FC<Props> = ({
  handleClose,
  handleSave,
  setFilterName,
  setFilterDescription,
  setFilterShouldNotify,
  filterName,
  filterShouldNotify,
  filterDescription,
  open,
}) => {
  const { savedFilters } = useFindingsContext();
  const isDisabled = useMemo(() => !filterName || savedFilters.some((filter) => filter.name === filterName), [filterName, savedFilters]);
  // The user cannot save the filter if the name is empty or already exists.

  return (
    <Dialog
      maxWidth='xl'
      onClose={handleClose}
      open={open}
      PaperProps={{ style: paperStyle }}
      scroll='paper'
    >
      <DialogTitle>
        <JitText
          bold
          size='l'
          text='dialogs.planItemsCatalog.filter.savedFilters.createNew'
        />
      </DialogTitle>

      <Divider />

      <div style={{ padding: 20 }}>
        <div style={{ paddingBottom: '1rem' }}>
          <div>
            <JitText
              style={{
                paddingBottom: 5,
                paddingLeft: 8,
              }}
              text='dialogs.planItemsCatalog.filter.savedFilters.name'
            />

            <JitTextInput
              {...inputDefaultProps}
              defaultValue={filterName}
              isError={isDisabled}
              isRequired
              onChange={(e) => setFilterName(e)}
              value={filterName}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <JitText
              style={{
                paddingBottom: 5,
                paddingLeft: 8,
              }}
              text='dialogs.planItemsCatalog.filter.savedFilters.description'
            />

            <JitTextInput
              {...inputDefaultProps}
              defaultValue={filterDescription}
              maxRows={4}
              multiline
              onChange={(e) => setFilterDescription(e)}
              rows={4}
            />
          </div>
        </div>

        <FindingsFilterSaveFindingDialogActions
          filterShouldNotify={filterShouldNotify}
          handleClose={handleClose}
          handleSave={handleSave}
          isDisabled={isDisabled}
          setFilterShouldNotify={setFilterShouldNotify}
        />
      </div>

    </Dialog>
  );
};
