import { FC, ReactElement, useEffect } from 'react';

import styles from './Toast.module.scss';

import { Cross } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { IAnalyticsEvent, ISvg } from 'types/interfaces';

export interface ToastActions {
  text: string;
  onClick: () => void;
}

export interface ToastProps {
  icon?: FC<ISvg>;
  title?: string;
  subtitle?: string;
  actions?: [ToastActions, ToastActions?, ToastActions?];
  analytics?: IAnalyticsEvent
  secondsToClose?: number;
  backgroundColor?: string;
  textColor?: string;
  handleClose?: () => void;
  content?: ReactElement
  borderRadius?: string;
  width?: string;
  margin?: string;
  display?: string;
  justifyContent?: string;
  padding?: string;
  enableClose?: boolean;
}

interface Props extends ToastProps {
  type: ToastType;
  index: number;
}

export const Toast: FC<Props> = ({
  content, icon, title, subtitle,
  actions, type, analytics, index,
  secondsToClose = 0,
  backgroundColor = colors.white, textColor = colors.black, borderRadius, width, margin,
  handleClose, display, justifyContent, padding, enableClose = true,
}) => {
  const { hideToast } = useToastsContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleDismissClick = () => {
    if (handleClose) {
      handleClose();
    }
    hideToast(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (secondsToClose) {
        hideToast(index);
      }
    }, secondsToClose * 1000);
    return () => clearTimeout(timer);
  }, [hideToast, index, secondsToClose]);

  useEffect(() => {
    if (!analytics) {
      return;
    }
    sendAnalyticsEvent(analytics);
  }, [analytics, sendAnalyticsEvent]);

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundColor,
        borderRadius,
        width,
        margin,
        padding,
      }}
    >
      <div
        className={styles.leftSide}
        style={{
          display,
          justifyContent,
        }}
      >
        {icon && <JitIcon color={colors.blue} icon={icon} />}

        {title && <JitText bold color={textColor} noWrap text={title} />}

        {subtitle && <JitText color={textColor} noWrap overflowHiddenEllipsis text={subtitle} />}

        {content}

        {actions?.map((action) => {
          if (!action) return null;
          const { text, onClick } = action;
          return (
            <JitButton
              key={text}
              analytics={{ action: `clicked-toast-${type}-action-${text}` }}
              data-testid={`toast-${type}-action-${text}`}
              onClick={onClick}
              variant='text'
            >
              <JitText className={styles.actionText} color={textColor} noWrap text={text} />
            </JitButton>
          );
        })}

      </div>

      {enableClose && <JitIcon color={textColor} icon={Cross} onClick={handleDismissClick} />}
    </div>
  );
};

