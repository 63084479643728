import { FC, useCallback, useState } from 'react';

import styles from './NavButton.module.scss';
import { useNavButtonStyles } from './useNavButtonStyles';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { IconProps } from 'components/Navbar/hooks';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { isSvg } from 'utils/functions/isSvg';

interface Props {
  icon?: FC<ISvg> | FC<IconProps>;
  text: string,
  rightSideIcon?: FC<ISvg> | FC<IconProps>;
  onClick: () => void,
  isSelected?: boolean,
  isCollapsed: boolean,
  isNew?: boolean,
  isDisabled?: boolean,
}

type IconParams = {
  color: string;
  size: number;
  iconToRender: FC<ISvg> | FC<IconProps>;
};

const renderIcon = ({ color, size, iconToRender }: IconParams) => {
  if (isSvg(iconToRender)) {
    return <JitIcon color={color} icon={iconToRender as FC<ISvg>} size={size} />;
  }

  const Icon = iconToRender as FC<IconProps>;

  return <Icon color={color} size={size} />;
};

export const NavButton: FC<Props> = ({
  icon,
  text,
  rightSideIcon,
  onClick,
  isSelected,
  isCollapsed,
  isNew,
  isDisabled,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { backgroundColor, textAndIconColor } = useNavButtonStyles(isDisabled, isSelected, isHovered);

  const onMouseEnter = useCallback(() => {
    if (!isDisabled) setIsHovered(true);
  }, [isDisabled]);

  const onMouseLeave = useCallback(() => {
    if (!isDisabled) setIsHovered(false);
  }, [isDisabled]);

  const handleClick = useCallback(() => {
    if (!isDisabled) onClick();
  }, [isDisabled, onClick]);

  return (
    <div
      className={styles.wrapper}
      data-testid={`Navbar button-${text}`}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role='button'
      style={{ backgroundColor }}
      tabIndex={isDisabled ? -1 : 0}
    >
      <div className={styles.leftSide}>
        <div className={styles.iconWrapper}>
          {isNew && (
            <div className={styles.newIconWrapper}>
              <JitText bold color={colors.black} size='xxs' text='navbar.newIcon' />
            </div>
          )}

          {icon && renderIcon({ color: textAndIconColor,
            size: 22,
            iconToRender: icon })}
        </div>

        {!isCollapsed && (
          <JitText color={textAndIconColor} noWrap text={text} />
        )}
      </div>

      {!isCollapsed && rightSideIcon && renderIcon({ color: textAndIconColor,
        size: 18,
        iconToRender: rightSideIcon })}
    </div>
  );
};
