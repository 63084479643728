import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { Check, Section } from 'pages/QuickStartGuide/types';
import { IInstallation, IIntegration, IPreferences } from 'types/interfaces';

interface ITenantContext {
  installations: IInstallation[];
  hasTriedFetchInstallations: boolean;
  preferences?: IPreferences;
  setPreferences: Dispatch<SetStateAction<IPreferences | undefined>>,
  isPreferencesLoading: boolean;
  initPreferences: () => Promise<void>;
  isLoadingInstallation: boolean;
  githubInstallation?: IInstallation;
  gitlabInstallation?: IInstallation;
  getIntegrations: () => IIntegration[];
  initUserPreferences: () => Promise<void>;
  isGithubIntegrated: boolean;
  isGitlabIntegrated: boolean;
  scmInstallations: IInstallation[];
  isScmVendorIntegrated: (vendor: string) => boolean;
  isOtherScmVendorIntegrated: (vendor: string) => boolean;
  isAnyScmVendorIntegrated: boolean;
  currentScmVendor?: string;
  getInstallationForSCMVendor: (vendor: string) => IInstallation | undefined;
  initialOnboardingCompleted: boolean;
  SCMIntegrationCompleted: boolean;
  checks: Check[];
  sections: Section[];
  isChecksLoading: boolean;
  setCheckAsCompleted: (checkSlug: string) => void;
  isIntegrationsLoading: boolean;
}

export const TenantContext = createContext<ITenantContext>({
  installations: [],
  hasTriedFetchInstallations: false,
  isLoadingInstallation: true,
  setPreferences: () => {},
  isPreferencesLoading: false,
  initPreferences: () => Promise.resolve(),
  getIntegrations: () => [],
  initUserPreferences: () => Promise.resolve(),
  isGithubIntegrated: false,
  isGitlabIntegrated: false,
  scmInstallations: [],
  isScmVendorIntegrated: () => false,
  isOtherScmVendorIntegrated: () => false,
  isAnyScmVendorIntegrated: false,
  currentScmVendor: undefined,
  getInstallationForSCMVendor: () => undefined,
  initialOnboardingCompleted: false,
  SCMIntegrationCompleted: false,
  checks: [],
  sections: [],
  isChecksLoading: true,
  setCheckAsCompleted: () => {},
  isIntegrationsLoading: true,
});

export const useTenantContext = () => useContext(TenantContext);
