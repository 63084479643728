import { FC } from 'react';

import { RisksBreadcrumbs } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import { Header } from 'pages/Resources/ResourcesPage/component/Header/Header';
import { ResourcesTable } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTable';
import { ResourcesTableFilters } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTableFilters/ResourcesTableFilters';
import { TotalResourcesCount } from 'pages/Resources/ResourcesPage/component/ResourcesTable/TotalResourcesCount/TotalResourcesCount';
import styles from 'pages/Resources/ResourcesPage/ResourcesPage.module.scss';

export const ResourcesPage: FC = () => (
  <div className={styles.wrapper}>
    <RisksBreadcrumbs />

    <Header />

    <div className={styles.body}>
      <ResourcesTableFilters />

      <TotalResourcesCount />

      <ResourcesTable />
    </div>
  </div>
);
