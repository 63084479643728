import { exists, t } from 'i18next';
import { cloneDeep, groupBy, isEmpty, startCase } from 'lodash';
import { createContext, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useActivationFlowStateNew } from 'context/PipelinesContext/hooks/useActivationFlowState';
import {
  useHandlePipelinesWebSocketNotificationNew,
} from 'context/PipelinesContext/hooks/useHandlePipelinesWebSocketNotification/useHandlePipelinesWebSocketNotificationNew';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { ConvertPipelineResponseIntoIPipelineNew } from 'pages/PipelinesPage/utils';
import { usePipelineService } from 'services/PipelinesService/usePipelineService';
import { AssetType, JitEventName as JitEventNameEnum, WebSocketNotificationTopics } from 'types/enums';
import { PipelineStatus } from 'types/enums/PipelineStatus';
import { IAsset } from 'types/interfaces';
import { IFilter, IFilterOption, IFilterType } from 'types/interfaces/IFilter';
import { IMockPipelineNew, IPipelineNew, IPipelineStateNew } from 'types/interfaces/Pipelines/IPipelineNew';
import { sortAlphabetic } from 'utils/functions/sortAlphabetic';

interface IPipelinesContextNew {
  pipelinesState: IPipelineStateNew;
  setPipelinesState: Dispatch<SetStateAction<IPipelineStateNew>>
  mockPipeline: IMockPipelineNew | null;
  setMockPipeline: Dispatch<SetStateAction<IMockPipelineNew | null>>;
  secondaryMockPipeline: IMockPipelineNew | null;
  setSecondaryMockPipeline: Dispatch<SetStateAction<IMockPipelineNew | null>>;
  showActivationPopper: boolean;
  setShowActivationPopper: Dispatch<SetStateAction<boolean>>;
  fetchMore: () => void;
  setIsLoading: (isLoading: boolean) => void;
  fetchPipelines: (newFilters: IFilter[], startKey?: string) => void;
  filters: Array<IFilter>;
  setFilters: (newFilters: IFilter[]) => void;
  selectedPipeline: IPipelineNew | null;
  setSelectedPipeline: Dispatch<SetStateAction<IPipelineNew | null>>;
}

export const PipelinesContextNew = createContext<IPipelinesContextNew>({
  pipelinesState: {
    pipelines: undefined,
    hasLoaded: false,
    lastEvalKey: undefined,
    isLoading: false,
  },
  setPipelinesState: () => undefined,
  mockPipeline: null,
  setMockPipeline: () => undefined,
  secondaryMockPipeline: null,
  setSecondaryMockPipeline: () => undefined,
  showActivationPopper: true,
  setShowActivationPopper: () => undefined,
  fetchMore: () => undefined,
  setIsLoading: () => undefined,
  fetchPipelines: () => undefined,
  filters: [],
  setFilters: () => undefined,
  selectedPipeline: null,
  setSelectedPipeline: () => undefined,
});

export const usePipelineContextNew = () => useContext(PipelinesContextNew);

const pipelinesInitialState = {
  pipelines: undefined,
  hasLoaded: false,
  lastEvalKey: undefined,
  isLoading: false,
};

enum FiltersEntityKeys {
  Resource = 'sourceAssetName',
  Status = 'status',
  JitEventName = 'jitEventName',
}

const PIPELINES_FILTERS: IFilter[] = [
  {
    entityKey: FiltersEntityKeys.Resource,
    displayText: 'Resource',
    type: IFilterType.SINGLE_SELECT,
    valueOptions: [{
      value: '',
      displayText: 'All Resources',
    }],
    valueOptionsWithCategories: [],
    withSearchBox: true,
    width: '260px',
  },
  {
    entityKey: FiltersEntityKeys.JitEventName,
    displayText: 'Event',
    type: IFilterType.SINGLE_SELECT,
    width: '260px',
    valueOptions: [
      {
        value: '',
        displayText: 'All Events',
      },
      ...Object.values(JitEventNameEnum).map((eventName) => {
        if (exists(`pages.pipelines.filters.events.${eventName}`)) {
          return ({
            value: eventName,
            displayText: t(`pages.pipelines.filters.events.${eventName}`),
          });
        }
        return ({
          value: eventName,
          displayText: startCase(eventName),
        });
      }).sort((a, b) => sortAlphabetic(a.displayText, b.displayText))],
  },

  {
    entityKey: FiltersEntityKeys.Status,
    displayText: 'Status',
    type: IFilterType.SINGLE_SELECT,
    width: '160px',
    valueOptions: [
      {
        value: '',
        displayText: 'All Statuses',
      },
      ...Object.values(PipelineStatus).map((value) => ({
        value,
        displayText: startCase(value),
      })).sort((a, b) => sortAlphabetic(a.displayText, b.displayText))],
  },
];

// eslint-disable-next-line max-statements
export const NewPipelinesProvider: FC = ({ children }) => {
  const { websocketSubscribe } = useWebsocketSubscribe();
  const [pipelinesState, setPipelinesState] = useState<IPipelineStateNew>(pipelinesInitialState);
  const [selectedPipeline, setSelectedPipeline] = useState<IPipelineNew | null>(null);
  const [filters, setFilters] = useState<IFilter[]>(PIPELINES_FILTERS);
  const {
    mockPipeline, setMockPipeline, secondaryMockPipeline, setSecondaryMockPipeline, showActivationPopper, setShowActivationPopper,
  } = useActivationFlowStateNew();
  const { handlePipelinesWebSocketNotification } = useHandlePipelinesWebSocketNotificationNew({
    setPipelinesState,
    filters,
    selectedPipeline,
    setSelectedPipeline,
  });
  const { assets, isLoadingAssets } = useAssetsContext();
  const { configurations } = useConfigurationsContext();
  const { getNewPipelines } = usePipelineService();

  const setIsLoading = (isLoading: boolean) => {
    setPipelinesState((currentPipelinesState) => ({
      ...currentPipelinesState,
      isLoading,
    }));
  };

  const getFilterSelectedValueByEntityKey = useCallback((newFilters: IFilter[], entityKey: string) => {
    const foundFilter = newFilters.find((filter) => filter.entityKey === entityKey);
    const filterSelectedValue: IFilterOption = (foundFilter && isEmpty((foundFilter?.selectedValue as IFilterOption)?.value)
      ? {
        value: '',
        displayText: '',
      }
      : foundFilter?.selectedValue) as IFilterOption;

    return filterSelectedValue?.value;
  }, []);

  const fetchPipelines = useCallback(async (newFilters: IFilter[], startKey?: string) => {
    setIsLoading(true);
    const status = getFilterSelectedValueByEntityKey(newFilters, FiltersEntityKeys.Status);
    const assetName = getFilterSelectedValueByEntityKey(newFilters, FiltersEntityKeys.Resource);
    const jitEventName = getFilterSelectedValueByEntityKey(newFilters, FiltersEntityKeys.JitEventName);

    const assetId = assets?.find((asset) => asset.asset_name === assetName)?.asset_id;
    const params = {
      startKey,
      assetId,
      status,
      jitEventName,
    };
    const fetched = await getNewPipelines(params);
    const newLastEvalKey = fetched?.data?.metadata?.last_key;
    const shouldAppendPipelines = !!startKey; // If there is a startKey we fetch for scroll and should append otherwise should stash.
    setPipelinesState((currentPipelinesState) => {
      const newPipelines = ConvertPipelineResponseIntoIPipelineNew(fetched?.data?.data || []);
      const pipelinesToSet = shouldAppendPipelines ? [...(currentPipelinesState.pipelines || []), ...newPipelines] : [...newPipelines];
      return ({
        ...currentPipelinesState,
        pipelines: pipelinesToSet,
        lastEvalKey: newLastEvalKey,
        isLoading: false,
        hasLoaded: true,
      });
    });
  }, [assets, getFilterSelectedValueByEntityKey, getNewPipelines]);

  const assetsWithAwsType = useMemo(() => assets?.map((asset) => {
    if (asset.asset_type === AssetType.AWS_ACCOUNT) {
      const assetWithAwsType = (cloneDeep(asset) as IAsset);
      const awsConfiguration = configurations?.aws?.find((configuration) => configuration.account_id === assetWithAwsType.owner);
      assetWithAwsType.asset_type = awsConfiguration?.type as AssetType || assetWithAwsType.asset_type;
      return assetWithAwsType;
    }
    return asset;
  }), [assets, configurations?.aws]);

  const reFetchPipelines = useCallback((newFilters: IFilter[]) => {
    // remove all existing pipelines when filters are changed
    setPipelinesState((currentPipelinesState) => ({
      ...currentPipelinesState,
      hasLoaded: false,
      lastEvalKey: undefined,
    }));
    fetchPipelines(newFilters);
  }, [fetchPipelines]);

  const setFiltersWithCallback = useCallback((newFilters: IFilter[]) => {
    setFilters((prevFilters) => {
      const hasFiltersSelectionChanged = newFilters.some((newFilter) => {
        const prevFilter = prevFilters.find((filter) => filter.entityKey === newFilter.entityKey);
        return prevFilter?.selectedValue !== newFilter.selectedValue;
      });
      if (hasFiltersSelectionChanged) reFetchPipelines(newFilters);

      return newFilters;
    });
  }, [reFetchPipelines]);

  const setResourceFilterData = useCallback(() => {
    const resourceFilter = PIPELINES_FILTERS.find((filter) => filter.entityKey === FiltersEntityKeys.Resource);
    if (isLoadingAssets) return;

    const assetsByAssetType = groupBy(assetsWithAwsType, 'asset_type');

    const valueOptionsWithCategories = Object.entries(assetsByAssetType).map((assetGroup) => {
      const assetType = assetGroup[0];
      const assetGroupa = assetGroup[1];
      return ({
        category: exists(`pages.pipelines.filters.resource.category.${assetType}`)
          ? t(`pages.pipelines.filters.resource.category.${assetType}`)
          : startCase(assetType),
        valueOptions: assetGroupa.map((asset) => ({
          value: asset.asset_name,
          displayText: asset.asset_type === AssetType.REPO ? `${asset.owner}/${asset.asset_name}` : asset.asset_name,
        })).sort((a, b) => sortAlphabetic(a.displayText, b.displayText)),
      });
    }).sort((a, b) => sortAlphabetic(a.category, b.category));

    const newFilters = PIPELINES_FILTERS.map((filter) => (
      filter.entityKey === resourceFilter!.entityKey ? {
        ...resourceFilter!,
        valueOptionsWithCategories,
      } : filter));
    setFilters(newFilters);
  }, [assetsWithAwsType, isLoadingAssets, setFilters]);

  useEffect(() => {
    setResourceFilterData();
  }, [setResourceFilterData]);

  const lastEvalKeyRef = useRef<string>();

  const fetchMore = useCallback(() => {
    if (pipelinesState.lastEvalKey && lastEvalKeyRef.current !== pipelinesState.lastEvalKey) {
      lastEvalKeyRef.current = pipelinesState.lastEvalKey;
      fetchPipelines(filters, pipelinesState.lastEvalKey);
    }
  }, [filters, fetchPipelines, pipelinesState.lastEvalKey]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Pipeline, handlePipelinesWebSocketNotification);
  }, [handlePipelinesWebSocketNotification, websocketSubscribe]);

  const value = useMemo(() => ({
    pipelinesState,
    setPipelinesState,
    mockPipeline,
    setMockPipeline,
    secondaryMockPipeline,
    setSecondaryMockPipeline,
    showActivationPopper,
    setShowActivationPopper,
    fetchMore,
    fetchPipelines,
    setIsLoading,
    filters,
    setFilters: setFiltersWithCallback,
    selectedPipeline,
    setSelectedPipeline,
  }), [
    pipelinesState,
    mockPipeline,
    setMockPipeline,
    secondaryMockPipeline,
    setSecondaryMockPipeline,
    showActivationPopper,
    setShowActivationPopper,
    fetchMore,
    fetchPipelines,
    filters,
    setFiltersWithCallback,
    selectedPipeline,
  ]);

  return <PipelinesContextNew.Provider value={value}>{children}</PipelinesContextNew.Provider>;
};

