import { FC } from 'react';

import { getColorByScore } from 'utils/functions/grades';

const OFFSET = 7;
const RADIUS = 4;

export const CustomDot: FC = (props) => {
  // @ts-ignore - currently recharts does not export the correct type
  const { cx, cy, payload } = props;
  if (!payload?.score) return null;

  // Adjust the x position slightly
  const adjustedCx = cx + OFFSET;

  return (
    <circle cx={adjustedCx} cy={cy} data-testid='custom-dot-circle' fill={getColorByScore(payload.score)} r={RADIUS} />
  );
};
