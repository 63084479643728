import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export const useCalculateMaxVisibleElementsCount = (elements: React.ReactElement[], containerWidth: number): number => {
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    const tempContainer = document.createElement('div');
    tempContainer.style.visibility = 'hidden';
    document.body.appendChild(tempContainer);

    ReactDOM.render(
      elements,
      tempContainer,
      () => {
        let totalWidth = 0;
        let count = 0;

        Array.from(tempContainer.children).forEach((child: Element) => {
          const childWidth = child.clientWidth;
          if (totalWidth + childWidth > containerWidth) return;
          totalWidth += childWidth;
          count += 1;
        });

        ReactDOM.unmountComponentAtNode(tempContainer);
        document.body.removeChild(tempContainer);

        setVisibleCount(count);
      },
    );

    // Cleanup function to avoid memory leaks
    return () => {
      if (!tempContainer) return;
      ReactDOM.unmountComponentAtNode(tempContainer);
    };
  }, [elements, containerWidth]);

  return visibleCount;
};
