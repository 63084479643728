import { FC, useMemo } from 'react';

import styles from './IconTextBox.module.scss';

import { JitText, TextSize } from 'components/JitText/JitText';

interface IconTextBoxProps {
  icon?: JSX.Element;
  text: string | number;
  color: string;
  withBorder?: boolean;
  borderOpacity?: number;
  testId?: string;
  size?: TextSize;
}

export const IconTextBox: FC<IconTextBoxProps> = ({
  icon, text, color, withBorder = true, borderOpacity = 1, testId = 'iconTextBox', size = 's',
}) => {
  const borderColor = useMemo(() => (
    withBorder ? `${color}${Math.round(borderOpacity * 255).toString(16).padStart(2, '0')}` : 'transparent'
  ), [withBorder, color, borderOpacity]);

  return (
    <div className={styles.wrapper} data-testid={testId} style={{ borderColor }}>
      {icon}

      <JitText bold color={color} size={size} text={text} />
    </div>
  );
};
