import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetDefaultFindingFilters } from 'context/FindingsContext/hooks/useGetDefaultFindingFilters';
import { constants } from 'globalConstants';
import { IFindingFilter, IFindingFilterType } from 'types/interfaces';
import { parseObjectToQueryParams, useQueryParams } from 'utils';

export const useSyncFiltersWithQueryParams = (filters: IFindingFilter[]) => {
  const [hasFiltersChanged, setHasFiltersChanged] = useState(false);
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const { getDefaultFilters } = useGetDefaultFindingFilters();
  const { pathname } = useLocation();

  const filterListToObject = useCallback((filtersList: IFindingFilter[]) => filtersList.filter((filter) => filter.isVisible
    && !isEmpty(filter.selectedValue)).reduce((currentAppliedFilters, filter) => ({
    ...currentAppliedFilters,
    [filter.key]: filter.selectedValue,
  }), {}), []);

  const appliedFilters = useMemo(() => filterListToObject(filters), [filters, filterListToObject]);

  const defaultFilters = getDefaultFilters();
  const defaultAppliedFilters = filterListToObject(defaultFilters.filter((filter) => filter.isVisible));

  const relevantQueryParams = useMemo(() => {
    const availableFilterKeys = defaultFilters.map((filter) => filter.key);
    return Object.fromEntries(Object.entries(queryParams).filter(([filterKey]) => availableFilterKeys.includes(filterKey)));
  }, [defaultFilters, queryParams]);

  const isFiltersAndQueryParamsSynced = useMemo(() => {
    if (!isEqual(Object.keys(appliedFilters), Object.keys(relevantQueryParams))) return false;

    let isSynced = true;

    Object.entries(appliedFilters).forEach(([filterKey, filterValue]) => {
      if (Array.isArray(filterValue)) {
        if (filterValue.join(',') !== relevantQueryParams[filterKey]) {
          isSynced = false;
        }
      } else if (filterValue !== relevantQueryParams[filterKey]) {
        isSynced = false;
      }
    });

    return isSynced;
  }, [appliedFilters, relevantQueryParams]);

  const syncQueryParamsOnFilterChange = useCallback(() => {
    if (!pathname.includes(constants.routes.FINDINGS) && !pathname.includes(constants.routes.risks.BACKLOG)) return;
    if (!isEqual(appliedFilters, defaultAppliedFilters)) {
      const appliedFiltersAsQueryParams = parseObjectToQueryParams(appliedFilters);
      if (!isEqual(relevantQueryParams, appliedFiltersAsQueryParams)) {
        navigate(`${pathname}?${appliedFiltersAsQueryParams}`, { replace: true });
      }
    }
  }, [appliedFilters, defaultAppliedFilters, navigate, pathname, relevantQueryParams]);

  const getUpdateFiltersWithInitialQueryParams = useCallback((existingFilters: IFindingFilter[]) => {
    const relevantQueryParamsKeys = Object.keys(relevantQueryParams);

    return existingFilters.map((filter) => {
      let updatedSelectedValue = filter.selectedValue;
      const filterValueType = typeof filter.valueOptions[0];
      const isFilterInKeys = relevantQueryParamsKeys.includes(filter.key);
      if (isFilterInKeys) {
        if (filter.type === IFindingFilterType.MULTI_SELECT) {
          updatedSelectedValue = relevantQueryParams[filter.key].split(',');
          if (filterValueType === 'boolean') {
            updatedSelectedValue = updatedSelectedValue.map((value) => value === 'true');
          }
        } else if (filter.type === IFindingFilterType.SINGLE_SELECT) {
          updatedSelectedValue = relevantQueryParams[filter.key];
          if (filterValueType === 'boolean') {
            updatedSelectedValue = updatedSelectedValue === 'true';
          }
        }
      }

      return {
        ...filter,
        selectedValue: updatedSelectedValue,
        isVisible: isFilterInKeys,
      };
    });
  }, [relevantQueryParams]);

  useEffect(() => {
    if (!isFiltersAndQueryParamsSynced) {
      if (hasFiltersChanged) {
        syncQueryParamsOnFilterChange();
      }
      setHasFiltersChanged(true);
    }
  }, [
    appliedFilters,
    hasFiltersChanged,
    isFiltersAndQueryParamsSynced,
    queryParams,
    getUpdateFiltersWithInitialQueryParams,
    syncQueryParamsOnFilterChange,
  ]);

  return {
    relevantQueryParams,
    getUpdateFiltersWithInitialQueryParams,
  };
};

