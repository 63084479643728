import { FC } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';

import styles from './CustomEdge.module.scss';
import { CustomEdgeData } from './types';

import { CircleQuestion } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

export const CustomEdge: FC<EdgeProps<CustomEdgeData>> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  source,
  target,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const description = data?.description;
  return (
    <>
      <BaseEdge path={edgePath} style={style} />

      <EdgeLabelRenderer>
        <div
          data-testid={`CustomEdge-${source}-${target}`}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: 'all',
          }}
        >
          {description && (
            <JitTooltip followCursor={false} placement='top' title={description}>
              <div className={styles.iconBackground}>
                <JitIcon
                  color={colors.priorityFactorGraphDefaultIconBg}
                  icon={CircleQuestion}
                  size={20}
                />
              </div>
            </JitTooltip>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

