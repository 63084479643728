import { Chip } from '@mui/material';
import { FC } from 'react';

import { Placement } from '../interfaces/ISegment';

import styles from './Activities.module.scss';

import colors from 'themes/colors.module.scss';

export interface IActivitiesProps {
  activities: string[];
  placement: Placement;
}

export const MAX_ACTIVITIES_TO_DISPLAY = 7;

export const Activities: FC<IActivitiesProps> = ({ activities, placement }) => {
  // if we have more than 7 activities, we only show the first 7 and add a +X more
  const hasAdditionalActivities = activities.length > MAX_ACTIVITIES_TO_DISPLAY;
  const additionalActivities = hasAdditionalActivities ? activities.length - MAX_ACTIVITIES_TO_DISPLAY : 0;
  const activitiesToDisplay = hasAdditionalActivities ? activities.slice(0, MAX_ACTIVITIES_TO_DISPLAY) : activities;

  const alignContent = (p: Placement) => {
    switch (p) {
      case Placement.Top:
        return 'flex-end';
      case Placement.Bottom:
        return 'flex-start';
      default:
        return 'center';
    }
  };

  // Add "+X" to the list if there are additional activities
  if (hasAdditionalActivities) {
    activitiesToDisplay.push(`+${additionalActivities}`);
  }
  return (
    <div
      className={styles.container}
      data-testid='activities-container'
      style={{ alignContent: alignContent(placement) }}
    >
      {activitiesToDisplay.map((activity) => (
        <Chip
          key={activity}
          classes={{
            label: styles.chipLabel,
          }}
          className={styles.chip}
          data-testid='activity-chip'
          label={activity}
          sx={{
            backgroundColor: colors.activityChipBackground,
            height: '30px',
            borderRadius: '14px',
            border: 'none',
            maxWidth: '210px',
          }}
          variant='outlined'
        />
      ))}
    </div>
  );
};
