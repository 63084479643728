import { FC } from 'react';

import styles from './FindingsTableGeneralHeader.module.scss';

import { ArrowDown, CircleQuestion } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { SortDirections } from 'types/enums';
import { ServerFindingKeys } from 'types/interfaces';

interface Props {
  columnKey: ServerFindingKeys;
  columnName: string;
  sortDirection?: SortDirections;
  onChangeSortOrder?: (columnKey: ServerFindingKeys) => void;
  infoTooltipText?: string;
}

export const FindingsTableGeneralHeader: FC<Props> = ({
  columnKey,
  columnName,
  onChangeSortOrder,
  sortDirection,
  infoTooltipText,
}) => {
  const hoveredClassName = onChangeSortOrder ? styles.hovered : '';
  const isAscending = sortDirection === SortDirections.ASC;
  return (
    <div
      className={`${styles.container} ${hoveredClassName}`}
      onClick={() => (onChangeSortOrder ? onChangeSortOrder(columnKey) : undefined)}
      role='button'
      tabIndex={0}
    >
      <JitText size='s' style={{ alignSelf: 'center' }} text={columnName} />

      {infoTooltipText
        && (
          <JitTooltip
            childrenWrapperClassName={styles.infoIcon}
            data-testid='InsightsCard-tooltip'
            followCursor={false}
            placement='top'
            title={infoTooltipText}
          >
            <JitIcon color={colors.white} icon={CircleQuestion} size={15} />
          </JitTooltip>
        )}

      {onChangeSortOrder && (<JitIcon className={styles.icon} icon={ArrowDown} rotateDegrees={isAscending ? 180 : 0} size={16} />)}

    </div>
  );
};

