import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

import { collectParentTestIds } from 'context/AnalyticsContext';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IAnalyticsEvent, IMouseEvent } from 'types/interfaces';

interface Props extends TooltipProps {
  translationParams?: object;
  childrenWrapperClassName?: string;
  withHighlightShadow?: boolean;
  offset?: [number, number];
  analytics?: IAnalyticsEvent;
}

export const JitTooltip = styled(({
  withHighlightShadow, childrenWrapperClassName, className, title, children, followCursor = true, placement = 'right', translationParams = {},
  offset = [0, 0], analytics,
  ...props
}: Props) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { t } = i18n;
  if (!title) return children;

  const sendAnalytics = (e: IMouseEvent) => {
    const eventAttributes: IAnalyticsEvent['params'] = {
      text: title.toString(),
      'data-testid': e.currentTarget.getAttribute('data-testid') || '',
      parentsPaths: collectParentTestIds(e.currentTarget),
    };

    const analyticAction = analytics?.action || e.currentTarget.getAttribute('data-testid') || 'tooltip-hovered';

    const analyticData: IAnalyticsEvent = {
      action: analyticAction,
      params: {
        ...eventAttributes,
        ...(analytics?.params || {}),
      },
    };
    sendAnalyticsEvent(analyticData);
  };

  return (
    <Tooltip
      classes={{
        popper: className,
      }}
      componentsProps={{
        tooltip: {
          style: {
            margin: '0',
            boxShadow: withHighlightShadow ? `0 0 6px ${colors.tooltipBoxShadow}` : undefined,
          },
        },
      }}
      data-testid={`jit-tooltip-${title}`}
      followCursor={followCursor}
      onMouseEnter={sendAnalytics}
      placement={placement}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ],
        ...props.PopperProps,
      }}
      title={typeof title === 'string' ? t(title, translationParams) || '' : title}
      {...props}
    >
      {/* This 'div' wrapper is required to ensure the tooltip would work on every 'children' correctly */}

      <div className={childrenWrapperClassName}>
        {children}
      </div>
    </Tooltip>
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.black,
    color: colors.white,
  },
}));
