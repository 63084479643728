import { useQuery } from 'react-query';

import { getScoreTrendMetric } from 'services/MetricService/MetricService';
import { NonStandardTimeAgo } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { DayScore, EntityDaysScore, ScoreEntityType } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';

interface IScoreTrendingWeek {
  week: string;
  avgScore: number | null;
  days: DayScore[];
}

export interface IScoreTrendingEntity {
  entityId: string;
  weeks: IScoreTrendingWeek[];
}

// Helper function to group scores by week
const groupScoresByWeek = (entity: EntityDaysScore): IScoreTrendingWeek[] => {
  const weeks: IScoreTrendingWeek[] = [];
  let currentWeekDays: DayScore[] = [];

  entity.scoresByDay.forEach((day, index) => {
    currentWeekDays.push(day);

    // Every 7 days or on the last day, create a week
    if ((index + 1) % 7 === 0 || index === entity.scoresByDay.length - 1) {
      let avgScore = null;
      const daysWithScore = currentWeekDays.filter((weekDay) => weekDay.score !== null);
      if (daysWithScore.length > 0) {
        avgScore = Math.round(currentWeekDays.reduce((acc, curr) => acc + curr.score!, 0) / daysWithScore.length);
      }
      weeks.push({
        week: `w${weeks.length + 1}`,
        avgScore,
        days: [...currentWeekDays],
      });
      currentWeekDays = []; // Reset for the next week
    }
  });

  return weeks;
};

export const useGetScoreTrendingByWeek = (entityIds: string[]) => {
  const fetchMetric = async (): Promise<IScoreTrendingEntity[]> => {
    const response = await getScoreTrendMetric(NonStandardTimeAgo.SixWeeks, ScoreEntityType.Team, entityIds);
    if (response && response.entitiesScores) {
      return response.entitiesScores.map((entity) => ({
        entityId: entity.entityId,
        weeks: groupScoresByWeek(entity),
      }));
    }
    return [];
  };

  const { data: scoreTrending, isLoading } = useQuery([Queries.ScoreTrending, entityIds], fetchMetric, {
    enabled: !!entityIds.length,
  });

  return {
    scoreTrending,
    isLoading,
  };
};
