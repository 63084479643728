import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { validateZapApiFile } from '../ZapApiFileValidator';

import { ConfigurationsDropdownField } from './ConfigurationsDropdownField';
import styles from './ConfigurationsZapInputFields.module.scss';
import { ANALYTICS_EVENT_TITLE } from './constants';

import { ErrorIcon } from 'assets';
import { FieldsTextInterface } from 'components/Configurations/Zap/components/ConfigurationsZapField';
import { JitFileInput } from 'components/JitFileInput/JitFileInput';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { ZapUrlValidator } from 'context/ConfigurationsContext/validators/ZapValidators';
import { i18n } from 'locale/i18n';
import { usePlanService } from 'services/PlanService/usePlanService';
import { AssetType } from 'types/enums';
import { MenuItemKeyType, ZapAuthenticationConfigType } from 'types/interfaces';

interface Props {
  handleChange: (key: string, value: string) => void;
  handleChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputField: string;
  inputValue: string;
  type: ZapAuthenticationConfigType;
  dataTestid?: string,
}

enum FileMethod {
  UPLOAD = 'upload',
  URL = 'url',
}

export const ConfigurationsTargetUrlField: FC<Props> = ({
  handleChange, handleChangeInput, inputField, inputValue, type, dataTestid,
}) => {
  const initialSelectedMenuItem = inputValue && ZapUrlValidator(type === AssetType.API, inputValue) ? FileMethod.URL : FileMethod.UPLOAD;
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemKeyType>(initialSelectedMenuItem);
  const [isValidContent, setIsValidContent] = useState<boolean | undefined>(undefined);
  const { uploadFileToCentralizedRepo } = usePlanService();
  const fieldsText: FieldsTextInterface = useMemo(
    () => i18n.t(`configurations.zap.${type}.fields.${inputField}.fileMethods.${selectedMenuItem}`, { returnObjects: true }),
    [type, inputField, selectedMenuItem],
  );

  const fileMethodToIsValidMap = new Map<string, boolean>([
    [FileMethod.URL, ZapUrlValidator(type === AssetType.API, inputValue) && (isValidContent !== undefined ? isValidContent : true)],
  ]);

  const isFileContentValid = async (value: string) => {
    const { success } = await validateZapApiFile(value);
    return success;
  };

  const handleValidateURL = async (value: string) => {
    const isURLValid = ZapUrlValidator(type === AssetType.API, value);
    if (!isURLValid || !value) {
      return;
    }

    const valid = await isFileContentValid(value);
    setIsValidContent(valid);
  };

  const handleUpload = async (file: File) => {
    const response = await uploadFileToCentralizedRepo(file);
    if (response?.status && response.status >= 200 && response.status <= 299) {
      // set the value of the path from the response because JitInputFile returns the file name only
      handleChange(inputField, response.data.file_path);
    }

    return response;
  };

  const onInputChange = useCallback((value: string) => {
    // set the value to empty string if the user deletes the value.
    // to handle the case where the user actually uploaded file we already handled it in handleUpload method
    if (value === '') {
      handleChange(inputField, '');
    }
  }, [handleChange, inputField]);

  const uploadFileMethodItemComponent = (
    <JitFileInput
      accept={fieldsText.accept}
      analyticsTitle={ANALYTICS_EVENT_TITLE}
      data-testid={dataTestid}
      handleChange={onInputChange}
      handleUpload={handleUpload}
      inputValue={inputValue}
      isFileContentValid={isFileContentValid}
    />
  );

  const shouldShowError = fileMethodToIsValidMap.has(selectedMenuItem.toString()) && !fileMethodToIsValidMap.get(selectedMenuItem.toString());

  const urlFileMethodItemComponent = (
    <Input
      className={`${styles.inputFieldBox} ${shouldShowError && styles.inputFieldBoxFail}`}
      data-testid={dataTestid}
      disableUnderline
      endAdornment={shouldShowError && (
        <InputAdornment position='end'>
          <JitTooltip followCursor={false} placement='left' title={fieldsText.invalidToolTipTitle || ''}>
            <JitIcon
              className={styles.errorIcon}
              icon={ErrorIcon}
              size={16}
            />
          </JitTooltip>
        </InputAdornment>
      )}
      fullWidth
      margin='dense'
      maxRows={3}
      minRows={3}
      name={inputField}
      onBlur={(event) => handleValidateURL(event?.target.value)}
      onChange={handleChangeInput}
      placeholder={fieldsText.placeholder}
      sx={{
        padding: '0 0.5rem',
        fontSize: 14,
        borderRadius: '0.5rem',
      }}
      value={inputValue}
    />
  );

  const MenuItemKeyToComponent = {
    [FileMethod.UPLOAD]: uploadFileMethodItemComponent,
    [FileMethod.URL]: urlFileMethodItemComponent,
  };

  const menuItems = [
    FileMethod.UPLOAD,
    FileMethod.URL,
  ].map((menuItem) => ({
    itemKey: menuItem,
    itemName: `configurations.zap.${type}.fields.${inputField}.fileMethods.${menuItem}.header`,
    isSelected: selectedMenuItem === menuItem,
    itemContent: MenuItemKeyToComponent[menuItem],
  }));

  return (
    <ConfigurationsDropdownField
      fieldsText={fieldsText}
      handleChange={() => handleChange(inputField, '')}
      menuItems={menuItems}
      selectedMenuItem={selectedMenuItem}
      setSelectedMenuItem={setSelectedMenuItem}
    />
  );
};
