export enum ScoreEntityType {
  Team = 'team',
}

export interface DayScore {
  date: string;
  score: number | null;
}
export interface EntityDaysScore {
  entityId: string;
  scoresByDay: DayScore[];
}

export interface ScoreTrendMetric {
  entitiesType: ScoreEntityType;
  entitiesScores: EntityDaysScore[];
}
