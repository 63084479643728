import { FC } from 'react';

import { ResolvedFindingsWidget } from './ResolvedFindingsWidget/ResolvedFindingsWidget';

import { ScoreTrendingWidget } from 'components/ScoreTrendingWidget/ScoreTrendingWidget';
import { TopFindingsWidget } from 'pages/TeamsPortal/TeamPage/components/Insights/TopFindingsWidget/TopFindingsWidget';
import { IAsset } from 'types/interfaces';
import { IPaginatedState } from 'types/interfaces/IPaginatedState';
import { ScoreEntityType } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';
import { ITeam } from 'types/interfaces/Teams/ITeam';

interface Props {
  team: ITeam | undefined;
  resources: IPaginatedState<IAsset>
}

export const Insights: FC<Props> = ({
  team, resources,
}) => (
  <>
    {team && (<TopFindingsWidget disabled={!resources.isLoading && !resources.data.length} teamId={team.id} />)}

    {team && <ScoreTrendingWidget currentScore={team.score} entityId={team.id} entityType={ScoreEntityType.Team} />}

    {team && <ResolvedFindingsWidget teamName={team?.name} />}

  </>
);
