import { useCallback } from 'react';
import { QueryFunctionContext } from 'react-query';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import {
  IAggregatedAssetStatistics,
  IAggregatedAssetStatisticsSnakeCase,
  IAsset,
  IInitialScanStats,
  IUpdateAssetRequest,
} from 'types/interfaces';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useAssetService = () => {
  const { client } = useClient();

  const getAssets = useCallback(async (teamName?: string) => {
    const url = getApiUrls.assetService.getAssets();
    return client.get<IAsset[]>(
      {
        url,
        requestConfig: {
          params: {
            team: teamName,
          },
        },
        allowedStatuses: [200, 404],
      },
    );
  }, [client]);

  const updateMultipleAssets = useCallback(async (assets: IAsset[]) => {
    const url = getApiUrls.assetService.updateMultipleAssets();
    const assetsPatch: IUpdateAssetRequest[] = assets.map((asset) => ({
      asset_id: asset.asset_id,
      new_name: asset.asset_name,
      is_covered: asset.is_covered,
      tags: asset.tags,
    }));
    return client.post<IAsset[]>({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        data: assetsPatch,
      },
    });
  }, [client]);

  const getAssetsStatistics = useCallback(
    async ({
      queryKey,
    }: QueryFunctionContext<[string, string?]>): Promise<IAggregatedAssetStatistics[] | undefined> => {
      const [, aggregationKey] = queryKey;
      const url = getApiUrls.assetService.getAssetsStatistics(aggregationKey);
      const response = await client.get<IAggregatedAssetStatisticsSnakeCase[]>({
        url,
        allowedStatuses: [200, 400, 403],
      });

      if (response?.status === 200) {
        return camelizeSnakeCaseKeys(response.data) as IAggregatedAssetStatistics[];
      }
      throw new Error('Error fetching assets statistics');
    },
    [client],
  );

  const getInitialScanStats = useCallback(async () => {
    const url = getApiUrls.assetService.getInitialScanStats();
    const response = await client.get<IInitialScanStats[]>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IInitialScanStats[];
    }

    return undefined;
  }, [client]);

  return {
    getAssets,
    updateMultipleAssets,
    getAssetsStatistics,
    getInitialScanStats,
  };
};
