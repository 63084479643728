import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SeeDetailsButton.module.scss';

import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';

export const SeeDetailsButton: FC = () => {
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`); // TODO: Need to attach query params here
  };
  return (
    <div
      className={styles.button}
      onClick={onClick}
      role='button'
      tabIndex={0}
    >
      <JitText size='s' text='pages.resources.resourcesDashboard.seeDetails' />
    </div>
  );
};
