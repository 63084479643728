import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { HeaderGroup } from 'react-table';

import { DefaultTableDesign, TableDesignVariants } from 'components/JitTable/constants';
import colors from 'themes/colors.module.scss';
import { hexColorToRGBA } from 'utils/functions/hexColorToRGBA';

interface Props {
  headerGroups: HeaderGroup<object>[];
  tableDesign?: TableDesignVariants;
  cellPadding?: string;
}

type StyleMap = {
  borderBottom: number;
  backgroundColor: string;
  topBorderRadius?: number;
  bottomBorderRadius?: number;
};

export const JitTableHeader: FC<Props> = ({ headerGroups, tableDesign = DefaultTableDesign, cellPadding = '16px' }) => {
  const variantToStyleMap: Record<TableDesignVariants, StyleMap> = {
    dark: {
      backgroundColor: colors.cards,
      borderBottom: 0,
    },
    bright: {
      backgroundColor: colors.cards,
      borderBottom: 0,
    },
    light: {
      backgroundColor: hexColorToRGBA(colors.welcomePageBackground),
      borderBottom: 0,
      topBorderRadius: 6,
      bottomBorderRadius: 0,
    },
  };

  return (
    <TableHead>
      {headerGroups?.map((headerGroup) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        const { headers } = headerGroup;
        return (
          <TableRow
            {...headerGroupProps}
            key={headers?.toString()}
            sx={{
              th: {
                borderBottom: variantToStyleMap[tableDesign].borderBottom,
                backgroundColor: variantToStyleMap[tableDesign].backgroundColor,
                '&:first-of-type': {
                  borderTopLeftRadius: variantToStyleMap[tableDesign].topBorderRadius,
                  borderBottomLeftRadius: variantToStyleMap[tableDesign].bottomBorderRadius,
                },
                '&:last-of-type': {
                  borderTopRightRadius: variantToStyleMap[tableDesign].topBorderRadius,
                  borderBottomRightRadius: variantToStyleMap[tableDesign].bottomBorderRadius,
                },
              },
            }}
          >
            {headers?.map((column) => {
              const columnProps = column.getHeaderProps();
              return (
                <TableCell
                  {...columnProps}
                  key={column.id}
                  sx={{
                    padding: cellPadding,
                    minWidth: column.minWidth,
                    width: column.width,
                  }}
                >
                  {column.render('Header')}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableHead>
  );
};
