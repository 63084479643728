/* eslint-disable react/jsx-max-depth */
import { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { ProtectedContextProviders } from './AuthContext/ProtectedContextProviders';
import { queryClient } from './QueryClient/QueryClient';

import { config } from 'config';
import { AuthProviderWrapper } from 'context/AuthContext/AuthProviderWrapper';
import { FeatureFlagProvider } from 'context/FeatureFlagProvider';
import { StiggExtendedProvider } from 'context/StiggContext';
import { JitThemeProvider } from 'context/ThemeContext/ThemeContext';

export const ContextProviders: FC = ({ children }) => {
  if (config.isTest) {
    return (
      <BrowserRouter>
        {children}
      </BrowserRouter>
    );
  }

  return (
    <JitThemeProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProviderWrapper>
          <FeatureFlagProvider>
            <StiggExtendedProvider apiKey={config.stiggKey}>
              <ProtectedContextProviders>
                {children}
              </ProtectedContextProviders>
            </StiggExtendedProvider>
          </FeatureFlagProvider>
        </AuthProviderWrapper>
      </QueryClientProvider>
    </JitThemeProvider>
  );
};
