import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { NotificationSuccess } from 'assets';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { OpenFixPRToast } from 'pages/FindingsPage/components/HandleFixSuggestionButton/OpenFixPRToast';
import { parseFindings } from 'services/FindingsService/utils/parseFindings';
import { WebSocketNotificationTopics, ToastType } from 'types/enums';
import { IFinding, IFindingServer } from 'types/interfaces/Findings';
import { IWebsocketNotification } from 'types/interfaces/WebSocket/IWebsocketNotification';

interface Props {
  setFindings: Dispatch<SetStateAction<IFinding[]>>,
}

export const useSubscribeToFindingsWebsocket = ({ setFindings }: Props) => {
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { showToast } = useToastsContext();

  const notifyFixPRAdded = useCallback((finding: IFinding) => {
    showToast({
      type: ToastType.Success,
      overrideProps: {
        icon: NotificationSuccess,
        title: 'openFixPR.openingToast.openingCompleted.title',
        content: <OpenFixPRToast link={finding.fixPRUrl} subtitle='openFixPR.openingToast.openingCompleted.subtitle' />,
        secondsToClose: 30,
      },
    });
  }, [showToast]);

  const updateFindings = useCallback(
    (updatedFindingsInput: IFinding[], currentFindings: IFinding[]) => currentFindings.map((prevFinding) => {
      const updatedFinding = updatedFindingsInput?.find((finding) => finding.id === prevFinding.id);
      const hasFixPRAdded = updatedFinding?.fixPRUrl && !prevFinding.fixPRUrl;
      if (hasFixPRAdded) notifyFixPRAdded(updatedFinding);
      return updatedFinding || prevFinding;
    }),
    [notifyFixPRAdded],
  );

  const handleFindingsUpdatesWebSocketNotification = useCallback((notification: IWebsocketNotification<IFindingServer>) => {
    const { message: { updated } } = notification;
    if (!updated) return;
    const parsedUpdated = parseFindings(updated);

    setFindings((prevState) => updateFindings(parsedUpdated, prevState));
  }, [setFindings, updateFindings]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Findings, handleFindingsUpdatesWebSocketNotification);
  }, [handleFindingsUpdatesWebSocketNotification, websocketSubscribe]);
};
