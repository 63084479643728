import { isEmpty } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import useStateWithRef from 'react-usestateref';
import { v4 as uuidv4 } from 'uuid';
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from 'websocket';

import { config } from 'config';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { WebSocketContext } from 'context/WebSocketContext';
import { MessageToServerActionTypes, WebSocketNotificationTopics } from 'types/enums';
import { IDictionary, IWebsocketNotification } from 'types/interfaces';
import { WebsocketNotificationCallback } from 'types/types';
import { jsonDump, jsonLoad } from 'utils/functions/json';

type WebsocketTopicsHandlers = IDictionary<IDictionary<WebsocketNotificationCallback<object>>>;

export const WebSocketProvider: FC = ({ children }) => {
  const [socketClient, setSocketClient, socketClientRef] = useStateWithRef<W3CWebSocket>();

  const [isConnectionOpen, setIsConnectionOpen] = useState(false);
  const [, setTopicsHandlers, topicsHandlersRef] = useStateWithRef<WebsocketTopicsHandlers>({});

  const { frontEggUser: { tenantId, accessToken: jitJwt } } = useAuthContext();

  const getTopicByHandlerId = useCallback((handlerId: string): WebSocketNotificationTopics => {
    const subscribedTopics = Object.keys(topicsHandlersRef.current) as WebSocketNotificationTopics[];
    const foundTopic = subscribedTopics.find((topic) => topicsHandlersRef.current[topic][handlerId]);
    return foundTopic as WebSocketNotificationTopics;
  }, [topicsHandlersRef]);

  const sendMessageToServer = useCallback((action: MessageToServerActionTypes, topic: WebSocketNotificationTopics) => {
    const subscriptionMessage = jsonDump({
      action,
      data: {
        tenant_id: tenantId,
        topic,
      },
    });
    socketClientRef.current?.send(subscriptionMessage);
  }, [socketClientRef, tenantId]);

  const sendSubscribeRequestToServer = useCallback((topic: WebSocketNotificationTopics) => {
    sendMessageToServer(MessageToServerActionTypes.SUBSCRIBE, topic);
  }, [sendMessageToServer]);

  const sendUnsubscribeRequestToServer = useCallback((topic: WebSocketNotificationTopics) => {
    sendMessageToServer(MessageToServerActionTypes.UNSUBSCRIBE, topic);
  }, [sendMessageToServer]);

  const unsubscribeTopic = useCallback((handlerId: string) => {
    const notificationTopic = getTopicByHandlerId(handlerId);
    if (!notificationTopic) {
      console.error(`Unable to find Websocket handler for topic: '${notificationTopic}' and id: '${handlerId}'`);
    } else {
      const updatedTopicsHandlers = { ...topicsHandlersRef.current };
      delete updatedTopicsHandlers[notificationTopic][handlerId];
      setTopicsHandlers(updatedTopicsHandlers);
      const isTopicWithoutHandlers = isEmpty(updatedTopicsHandlers[notificationTopic]);
      // if connection is not opened yet, we don't need to send unsubscribe request to the server.
      if (isTopicWithoutHandlers && isConnectionOpen) {
        sendUnsubscribeRequestToServer(notificationTopic);
      }
    }
  }, [topicsHandlersRef, setTopicsHandlers, sendUnsubscribeRequestToServer, isConnectionOpen, getTopicByHandlerId]);

  const setTopicHandler = useCallback((
    notificationTopic: WebSocketNotificationTopics,
    handler: WebsocketNotificationCallback<object>,
    handlerId: string,
  ) => {
    const notificationTopicHandlers = topicsHandlersRef.current[notificationTopic] || {};
    const newSubscriptionCallbacks = {
      ...topicsHandlersRef.current,
      [notificationTopic]: {
        ...notificationTopicHandlers,
        [handlerId]: handler,
      },
    };
    setTopicsHandlers(newSubscriptionCallbacks);
  }, [topicsHandlersRef, setTopicsHandlers]);

  const subscribeTopic = useCallback((notificationTopic: WebSocketNotificationTopics, handler: WebsocketNotificationCallback<object>) => {
    const isSubscribingToNewTopic = isEmpty(topicsHandlersRef.current[notificationTopic]);
    // if connection is not opened yet, we will send the subscription request only after connection is opened in 'onopen' callback.
    if (isSubscribingToNewTopic && isConnectionOpen) {
      sendSubscribeRequestToServer(notificationTopic);
    }
    const handlerId = uuidv4();
    setTopicHandler(notificationTopic, handler, handlerId);

    return { handlerId };
  }, [topicsHandlersRef, sendSubscribeRequestToServer, setTopicHandler, isConnectionOpen]);

  const handleNewMessage = useCallback(({ data }: IMessageEvent) => {
    const parsedMessage = jsonLoad<IWebsocketNotification<object>>(data as string);
    if (!parsedMessage) {
      console.error(`Received message is not a valid JSON: ${data}`);
      return;
    }
    const { topic } = parsedMessage;
    const messageTopicHandlers = topicsHandlersRef.current[topic] || {};
    const topicHandlers = Object.values(messageTopicHandlers);
    topicHandlers.forEach((handler) => {
      handler(parsedMessage);
    });
  }, [topicsHandlersRef]);

  const handleConnectionOpen = useCallback(() => {
    setIsConnectionOpen(true);

    const subscribedTopics = Object.keys(topicsHandlersRef.current) as WebSocketNotificationTopics[];
    // send subscription request to the server for all topics that we have handlers for (subscribed before connection was opened).
    subscribedTopics.forEach((topic) => {
      sendSubscribeRequestToServer(topic);
    });
  }, [sendSubscribeRequestToServer, topicsHandlersRef]);

  const initSocketClient = useCallback(() => {
    const connectUrl = `${config.webSocketBaseUrl}/websocket?Authorization=${jitJwt}&Tenant=${tenantId}`;
    const newSocketClient = new W3CWebSocket(connectUrl);
    newSocketClient.onmessage = handleNewMessage;
    newSocketClient.onopen = () => {
      handleConnectionOpen();
    };
    newSocketClient.onclose = () => {
      setIsConnectionOpen(false);
      setTimeout(() => {
        initSocketClient();
      }, 1000);
    };
    setSocketClient(newSocketClient);
  }, [setSocketClient, handleNewMessage, jitJwt, tenantId, handleConnectionOpen, setIsConnectionOpen]);

  useEffect(() => {
    if (!tenantId || !jitJwt || socketClient) return;
    initSocketClient();
  }, [tenantId, jitJwt, socketClient, initSocketClient]);

  const value = useMemo(() => ({
    subscribeTopic,
    unsubscribeTopic,
    setTopicHandler,
  }), [subscribeTopic, unsubscribeTopic, setTopicHandler]);

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

