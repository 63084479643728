import { FC } from 'react';

import { FindingsPage } from './FindingsPage';

import { FindingsProvider } from 'context/FindingsContext/FindingsProvider';

export const FindingsPageWrapper: FC = () => (
  <FindingsProvider>
    <FindingsPage />
  </FindingsProvider>
);
