import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { ITableQueryParams } from 'types/interfaces';
import { IFindingsCount, IGroupByFindingsCount } from 'types/interfaces/Findings/IFindingsCount';
import { ICreateTicket } from 'types/interfaces/TicketManager/ICreateTicket';
import { Ticket } from 'types/interfaces/TicketManager/Ticket';
import { parseObjectToQueryParams } from 'utils';
import { snakeCaseCamelizeKeys } from 'utils/functions/camelCaseConverter';

export const useFindingsService = () => {
  const { client } = useClient();

  const createTicket = useCallback(async (body: ICreateTicket): Promise<AxiosResponse<Ticket> | undefined> => {
    const url = getApiUrls.findingService.createTicket();
    return client.post<Ticket>({
      url,
      allowedStatuses: [201, 400],
      requestConfig: {
        data: snakeCaseCamelizeKeys(body),
      },
    });
  }, [client]);

  const getFindingsCount = useCallback(async (params: ITableQueryParams, backlog: boolean | null = true): Promise<AxiosResponse<IFindingsCount | IGroupByFindingsCount[]> | undefined> => {
    const queryParams = parseObjectToQueryParams({
      ...params,
      filters: {
        ...params.filters,
        backlog,
      },
    });
    const url = `${getApiUrls.findingService.getFindingsCount()}?${queryParams}`;
    return client.get<IFindingsCount | IGroupByFindingsCount[]>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  return {
    createTicket,
    getFindingsCount,
  };
};
