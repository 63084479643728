import { GraphEntity } from '../types';

import { Vendor, AssetType } from 'types/enums';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { IFinding, IAsset, ManualFactors } from 'types/interfaces';
import { IEntityGraph, IEntityGraphNode, IEntityGraphEdge } from 'types/interfaces/Graph/IEntityGraph';

const buildFactorsNodesAndEdges = (sourceNodeId: string, factors: string[]): IEntityGraph => {
  const nodes = factors.map((factor) => ({
    id: factor,
    type: factor,
    factors: [factor],
  }));

  const edges = nodes.map((node) => ({
    id: `${sourceNodeId}_${node.id}`,
    source: sourceNodeId,
    target: node.id,
  }));
  return {
    graph: {
      nodes,
      edges,
    },
  };
};

export const buildDefaultFindingGraph = (finding: IFinding): IEntityGraph => {
  const nodes: IEntityGraphNode[] = [{
    type: 'Finding',
    name: finding.name,
    id: finding.id,
  }];
  const edges = [];

  if (finding.priorityFactors) {
    const factorsGraph = buildFactorsNodesAndEdges(finding.id, finding.priorityFactors);
    nodes.push(...factorsGraph.graph.nodes);
    edges.push(...factorsGraph.graph.edges);
  }

  let relatedResourceNode = null;
  if (finding.codeAttributes) {
    const type = finding.vendor === Vendor.Github ? 'GitHub Repository' : 'GitLab Project';
    relatedResourceNode = {
      type,
      name: finding.locationText,
      id: `${type}#${finding.locationText}`,
    };
  } else if (finding.vendor === Vendor.AWS) {
    relatedResourceNode = {
      type: 'AWS Account',
      name: finding?.cloudAttributes?.accountId || '',
      id: `AWS Account#${finding?.cloudAttributes?.accountId}`,
    };
  }

  if (relatedResourceNode) {
    nodes.push(relatedResourceNode);
    edges.push({
      id: `${finding.id}-${relatedResourceNode.id}`,
      source: finding.id,
      target: relatedResourceNode.id,
    });
  }

  return {
    graph: {
      nodes,
      edges,
    },
  };
};

export const buildDefaultAssetGraph = (asset: IAsset): IEntityGraph => {
  if (asset.asset_type !== AssetType.REPO) {
    // Currently we don't support graph for non-repo assets
    return {
      graph: {
        nodes: [] as IEntityGraphNode[],
        edges: [] as IEntityGraphEdge[],
      },
    };
  }
  const type = asset.vendor === Vendor.Github ? 'GitHub Repository' : 'GitLab Project';
  const assetNode = {
    type,
    name: asset.asset_name,
    id: `${type}#${asset.asset_name}`,
  };
  const nodes: IEntityGraphNode[] = [assetNode];
  const edges = [];
  if (asset.priority_factors) {
    const factorsGraph = buildFactorsNodesAndEdges(asset.asset_id, asset.priority_factors);
    nodes.push(...factorsGraph.graph.nodes);
    edges.push(...factorsGraph.graph.edges);
  }
  return {
    graph: {
      nodes,
      edges,
    },
  };
};

export const getEntityType = (entity: GraphEntity): GraphEntityType => {
  // Identify the entity type by the presence of expected fields
  if ('asset_id' in entity) return GraphEntityType.ASSET;
  if ('fingerprint' in entity) return GraphEntityType.FINDING;
  throw new Error('Invalid entity type');
};

export const getEntityId = (entity: GraphEntity): string => {
  const entityType = getEntityType(entity);
  return entityType === GraphEntityType.ASSET ? (entity as IAsset).asset_id : (entity as IFinding).id;
};

export const getEntityPriorityFactors = (entity: GraphEntity): string[] => {
  const entityType = getEntityType(entity);
  const factors = entityType === GraphEntityType.FINDING ? (entity as IFinding).priorityFactors : (entity as IAsset).priority_factors;
  return factors || [];
};

export const getEntityManualFactors = (entity: GraphEntity): ManualFactors => {
  const entityType = getEntityType(entity);
  return entityType === GraphEntityType.FINDING ? (entity as IFinding).manualFactors : (entity as IAsset).manual_factors;
};

export const findRoot = (graph: IEntityGraph): IEntityGraphNode | null => {
  const targetNodeIds = new Set(graph.graph.edges.map((edge) => edge.target));

  // Find the nodes that are not in the targetNodeIds set, these are potential root nodes
  const rootNodes = graph.graph.nodes.filter((node) => !targetNodeIds.has(node.id));

  // Assuming the graph has only one root, return the first one, or null if none found
  return rootNodes.length > 0 ? rootNodes[0] : null;
};
