import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import {
  ISlackSendInvitationRequest,
  ISlackSendInvitationResponse,
} from 'types/interfaces/NotificationService/ISendInvitation';

export const useNotificationService = () => {
  const { client } = useClient();

  const sendInvitation = useCallback(async (request: ISlackSendInvitationRequest): Promise<AxiosResponse<ISlackSendInvitationResponse> | undefined> => {
    const url = getApiUrls.notificationsService.invitation();

    return client.post<ISlackSendInvitationResponse>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: {
          invitation_payload: {
            sender_name: request.senderName,
            sender_email: request.senderEmail,
            invitation_link: request.invitationLink,
          },
          targets: request.recipientsIds.map((recipientId) => (
            {
              slack: {
                channel_id: recipientId,
              },
            }
          )),
        },
      } });
  }, [client]);

  return {
    sendInvitation,
  };
};
