import React, { FC } from 'react';

import { IAnalyticsEvent } from '../Analytics';
import { ISvg } from '../ICommon';

import { JitTextProps } from 'components/JitText/JitText';

export enum ActionButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINED = 'outlined',
  DANGER = 'danger',
  SECONDARY_DANGER = 'secondaryDanger',
  JIT_PRIMARY = 'jitPrimary',
  JIT_OUTLINED = 'jitOutlined',
}

export interface ActionButton {
  label?: string
  handleClick: ((event: React.MouseEvent<HTMLButtonElement>) => void) | (() => void)
  handleMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void
  variant?: ActionButtonVariant
  disabled?: boolean
  tooltip?: string
  isLoading?: boolean
  shouldShowChildrenWhileLoading?: boolean;
  analytics?: IAnalyticsEvent;
  textProps?: JitTextProps
  icon?: FC<ISvg>;
  iconSize?: number;
  iconColor?: string;
  noHover?: boolean;
}
