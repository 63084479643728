import { FC } from 'react';

import styles from './GitLabCard.module.scss';

import { GitlabIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';

interface Props {
  shouldShowIcon?: boolean;
}

export const GitLabCard: FC<Props> = ({ children, shouldShowIcon = true }) => (
  <div className={styles.card} data-testid='GitLabCard'>
    <div className={styles.cardContent}>
      {shouldShowIcon && <JitIcon centered={false} icon={GitlabIcon} size={35} />}

      {children}

    </div>
  </div>
);

