import { FC } from 'react';

import styles from './PriorityFactorsBox.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  factors: {
    key: string;
    displayName: string;
  }[];
  action?: {
    onClick: (key: string) => void;
    selectedFactor?: string;
  }
}

export const PriorityFactorsBox: FC<Props> = ({ factors, action }) => {
  const isSelectedFactor = (factor: string) => (!action || (factor === action.selectedFactor));

  const calcFactorStyle = (index: number, factor: string) => {
    const isSelected = isSelectedFactor(factor);
    const isPrevSelected = !!action && (factors?.[index - 1]?.key === action.selectedFactor);

    let borderRadius = '8px';
    let borderRightWidth = 1;
    let borderLeftWidth = 1;
    if (factors.length > 1) {
      if (index === 0) borderRadius = '8px 0 0 8px';
      else if (index === factors.length - 1) borderRadius = '0 8px 8px 0';
      else borderRadius = '0';

      if (!isSelected) borderRightWidth = index === factors.length - 1 ? 1 : 0;
      if (isPrevSelected) borderLeftWidth = 0;
    }

    return {
      cursor: action ? 'pointer' : 'default',
      borderRadius,
      borderRightWidth,
      borderLeftWidth,
    };
  };

  return (
    <div className={styles.factorsSet}>
      {factors.map((factor, index) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          key={factor.key}
          className={isSelectedFactor(factor.key) ? styles.factorBox : styles.factorBoxNotSelected}
          data-testid={`factor-${factor.key}`}
          onClick={() => {
            action?.onClick(factor.key);
          }}
          role={action ? 'button' : 'text'}
          style={calcFactorStyle(index, factor.key)}
        >
          <JitText
            bold
            color={isSelectedFactor(factor.key) ? colors.criticalPriority : colors.lightGray}
            noWrap
            size='m'
            text={factor.displayName}
          />
        </div>

      ))}
    </div>
  );
};
