import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { uniqBy } from 'lodash';
import { FC, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import styles from './AssetsTable.module.scss';
import { AddAssetsButton } from './components/AddAssetsButton/AddAssetsButton';
import { EmptyAssetsTable } from './EmptyAssetsTable';
import { useGetAssetColumns } from './useGetAssetColumns';

import { IconWithDialog, IconWithDialogAction } from 'components/IconWithDialog/IconWithDialog';
import { ElementToShowOnHoverProps } from 'components/JitTable/components';
import { GroupedRow } from 'components/JitTable/components/GroupedTableRows/GroupedTableRows';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { constants } from 'globalConstants';
import { useCurrentTeam } from 'pages/TeamsPortal/hooks/useCurrentTeam/useCurrentTeam';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { ToastType, Vendor } from 'types/enums';
import { TagNames } from 'types/enums/Tags';
import { IAsset, IDictionary, ITableRow } from 'types/interfaces';
import { iconsMap } from 'utils';
import { PERMISSIONS } from 'wrappers/RBAC';

interface AssetsTableProps {
  resources: IAsset[];
  isLoading?: boolean;
}

export const AssetsTable: FC<AssetsTableProps> = ({ resources, isLoading }) => {
  const { updateMultipleAssets } = useAssetService();
  const { showToast } = useToastsContext();
  const { specificTeam } = useCurrentTeam();
  const columns = useGetAssetColumns();
  const filteredAssets = resources.filter((asset) => asset.is_active && asset.is_covered && !asset.is_archived);
  const tableInstance = useGetTableInstance(columns, filteredAssets);
  const navigate = useNavigate();
  const { hasPermission } = useAuthContext();

  const { uiDisableAddRepoInTeamPortal } = useFlags();

  const {
    teamsPortal: { BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE, PORTAL_TEAMS, PORTAL_RESOURCES },
  } = constants.routes;
  const shouldAllowEdit = useMemo(() => hasPermission(PERMISSIONS.WRITE_TEAMS), [hasPermission]);

  const groupAssetsByVendor = (assetRows: Row<ITableRow>[]): GroupedRow[] => {
    const vendors: Vendor[] = uniqBy(assetRows, 'original.vendor').map((row) => row.original.vendor as Vendor);
    return vendors.map((vendor) => {
      const isVendorEditable: IDictionary<string> = t(`pages.teamsPortal.teamPage.table.add.${vendor}`, {
        returnObjects: true,
        defaultValue: null,
      }); // return null if translation is not found

      return {
        groupText: vendor,
        groupIcon: iconsMap[vendor]?.icon,
        groupCta: shouldAllowEdit && isVendorEditable && !uiDisableAddRepoInTeamPortal
          ? (<AddAssetsButton teamName={specificTeam.team?.name!} vendor={vendor} />)
          : undefined,
        rows: assetRows.filter((row) => row.original.vendor === vendor),
      };
    });
  };

  const handleRowClick = (row: IAsset) => {
    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${specificTeam.team?.id}/${PORTAL_RESOURCES}/${row.asset_id}`);
  };

  const handleRemoveResource = async (event: React.MouseEvent<HTMLButtonElement>, asset: IAsset) => {
    const teamName = specificTeam.team?.name!;
    const assetToUpdate: IAsset = {
      ...asset,
      tags: asset.tags.filter((tag) => !(tag.name === TagNames.Teams && tag.value === teamName)),
    };
    const response = await updateMultipleAssets([assetToUpdate]);
    if (response?.data?.length === 1) {
      showToast({
        type: ToastType.Success,
        overrideProps: {
          title: 'pages.teamsPortal.teamPage.table.delete.success',
          secondsToClose: 3,
        },
      });
    }
  };

  const elementToShowOnRowHover = ({ row }: ElementToShowOnHoverProps) => {
    const asset = row.original as IAsset;

    return (
      <IconWithDialog
        action={IconWithDialogAction.DELETE}
        message={t('pages.teamsPortal.teamPage.table.delete.subtitle', {
          assetName: asset.asset_name,
          teamName: specificTeam.team?.name!,
        })}
        onConfirm={(event) => handleRemoveResource(event, asset)}
        subMessage='pages.teamsPortal.teamPage.table.delete.title'
        title='pages.teamsPortal.teamPage.table.delete.header'
        tooltip='pages.teamsPortal.teamPage.table.delete.tooltip'
      />
    );
  };
  const EmptyTableView: FC = useCallback(() => (
    <EmptyAssetsTable teamName={specificTeam.team?.name!} />
  ), [specificTeam.team?.name]);

  return (
    <div className={styles.tableWrapper} data-testid='assets-table'>

      <JitTable
        cellPadding='16px 40px 16px 40px'
        cellsNumber={columns.length}
        ElementToShowOnRowHover={shouldAllowEdit ? elementToShowOnRowHover : undefined}
        EmptyTableView={EmptyTableView}
        groupRowsBy={groupAssetsByVendor}
        headerCellPadding='7px 0px 7px 40px'
        isLoading={!!isLoading}
        onSelectRow={handleRowClick}
        rowHoverStyle={{ cursor: 'pointer' }}
        shouldDisplayWithPaper={false}
        showPagination={false}
        tableDesign='light'
        tableInstance={tableInstance}
      />
    </div>
  );
};
