import { useCallback } from 'react';

import { config } from 'config';
import { useTenantService } from 'services/TenantService/useTenantService';
import { IRole } from 'types/interfaces/TenantService/IRole';

export const useGetInvitationLink = () => {
  const { generateInvitationToken } = useTenantService();

  const getInvitationLink = useCallback(async (role: IRole) => {
    const invitationTokenResponse = await generateInvitationToken({
      role,
    });
    if (invitationTokenResponse?.data) {
      const { invitation_token: invitationToken, state_token: stateToken } = invitationTokenResponse.data;
      return `${config.platformBaseUrl}/signup?invitationToken=${invitationToken}${stateToken ? `&stateToken=${stateToken}` : ''}`;
    }
    return null;
  }, [generateInvitationToken]);

  return { getInvitationLink };
};
