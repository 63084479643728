import { FC, useCallback, useEffect, useState } from 'react';

import styles from '../../IntegrationsPage.module.scss';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { CreateSecretDialogContent } from 'components/SecretsManagementDialog/Components/CreateSecretDialogContent';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { IntegrationTemplate } from 'context/IntegrationsContext/templates/interfaces';
import { useSecretsContext } from 'context/SecretsContext';
import { useSecretService } from 'services/SecretsService/useSecretService';
import { ISvg } from 'types/interfaces';
import { IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

export const useAddSecretDialog = (integration: IntegrationTemplate | undefined) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const { tenantSecrets, fetchTenantSecrets } = useSecretsContext();
  const { saveTenantSecret } = useSecretService();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  useEffect(() => {
    fetchTenantSecrets();
  }, [fetchTenantSecrets]);

  const tenantSecret = tenantSecrets?.find((secret) => secret.name === integration?.options?.fields.name.value);

  const validation = (secretName?: string | undefined) => {
    if (tenantSecrets?.find((secret) => secret.name === secretName)) {
      return IntegrationStatus.SUCCESS;
    }
    return undefined;
  };

  const onClose = () => {
    setIsOpened(false);
  };

  const action = useCallback(
    () => {
      sendAnalyticsEvent({
        action: tenantSecret ? 'configure-secret-clicked' : 'connect-secret-clicked',
        params: {
          integration: integration?.key || '',
        },
      });
      setIsOpened(true);
    },
    [integration?.key, sendAnalyticsEvent, tenantSecret],
  );

  const onSave = useCallback(async (secretName, secretValue) => {
    await saveTenantSecret({
      secretName,
      secretValue,
    });
    sendAnalyticsEvent({
      action: tenantSecret ? 'update-single-secret-clicked' : 'add-single-secret-clicked',
      params: {
        integration: integration?.key || '',
      },
    });
    onClose();
  }, [integration?.key, saveTenantSecret, sendAnalyticsEvent, tenantSecret]);

  const dialogContent = (
    <div className={styles.dialogWrapper}>
      <CreateSecretDialogContent
        existingSecret={tenantSecret}
        fields={integration?.options?.fields}
        icon={integration?.icon as FC<ISvg>}
        link={integration?.link}
        onClose={onClose}
        onSave={onSave}
        showInfoMessage
        subtitle={integration?.description}
        title={integration?.title}
      />
    </div>
  );

  return {
    component: integration ? (
      <CustomDialog
        content={dialogContent}
        height='m'
        isOpen={isOpened}
        onClose={onClose}
        title='dialogs.secretsManagement.title'
        width='l'
        withDivider
      />
    ) : undefined,
    isOpened,
    action,
    validation,
  };
};
