import { FC } from 'react';

import { JitIcon } from '../JitIcon/JitIcon';

import styles from './JitActionButton.module.scss';

import { JitButton, JitButtonVariants } from 'components/JitButton/JitButton';
import { JitText, TextSize } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

interface Props {
  actionButton: ActionButton;
}

export const JitActionButton: FC<Props> = ({ actionButton }) => {
  const buttonVariantMap = {
    [ActionButtonVariant.PRIMARY]: {
      buttonVariant: 'contained' as JitButtonVariants,
      textProps: {
        color: colors.white,
        bold: true,
        size: 'm' as TextSize,
      },
    },
    [ActionButtonVariant.SECONDARY]: {
      buttonVariant: 'text' as JitButtonVariants,
      textProps: {
        size: 'm' as TextSize,
        color: colors.lightBlue,
        bold: true,
      },
    },
    [ActionButtonVariant.SECONDARY_DANGER]: {
      buttonVariant: 'text' as JitButtonVariants,
      textProps: {
        size: 'm' as TextSize,
        color: colors.failRed,
        bold: true,
      },
    },
    [ActionButtonVariant.OUTLINED]: {
      buttonVariant: 'outlined' as JitButtonVariants,
      textProps: {
        size: 'm' as TextSize,
        bold: true,
        color: 'inherit',
      },
    },
    [ActionButtonVariant.DANGER]: {
      buttonVariant: 'danger' as JitButtonVariants,
      textProps: {
        size: 'm' as TextSize,
        color: colors.white,
        bold: true,
      },
    },
    [ActionButtonVariant.JIT_PRIMARY]: {
      buttonVariant: 'jitContained' as JitButtonVariants,
      textProps: {
        size: 'm' as TextSize,
        color: colors.white,
        bold: true,
      },
    },
    [ActionButtonVariant.JIT_OUTLINED]: {
      buttonVariant: 'jitOutlined' as JitButtonVariants,
      textProps: {
        size: 'm' as TextSize,
        color: colors.jitPink,
        bold: true,
      },
    },
  };
  const { buttonVariant, textProps } = buttonVariantMap[actionButton?.variant || 'primary'];

  const button = (
    <JitButton
      key={actionButton.label}
      analytics={actionButton.analytics}
      data-testid={`actionButton-${actionButton.label}`}
      disabled={actionButton.disabled}
      isLoading={actionButton.isLoading}
      noHover={actionButton.noHover}
      onClick={actionButton.handleClick}
      onMouseEnter={actionButton.handleMouseEnter}
      showChildrenWhileLoading={actionButton.shouldShowChildrenWhileLoading}
      variant={buttonVariant}
    >
      <div className={styles.buttonChildrenWrapper}>
        {actionButton.icon && (
          <JitIcon
            color={actionButton.iconColor || textProps.color}
            data-testid={`actionButton-${actionButton.label}-icon`}
            icon={actionButton.icon}
            size={actionButton.iconSize || 14}
          />
        )}

        <JitText {...textProps} {...(actionButton.textProps || {})} text={actionButton.label} />
      </div>
    </JitButton>
  );

  return (actionButton.tooltip ? (
    <JitTooltip childrenWrapperClassName={styles.tooltipChildrenWrapperClassName} placement='top' title={actionButton.tooltip}>
      {button}
    </JitTooltip>
  ) : (
    button
  ));
};
