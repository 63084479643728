import Typography, { TypographyProps } from '@mui/material/Typography';
import * as React from 'react';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { sizesMap } from './constants';
import styles from './JitText.module.scss';

import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IDictionary, IMouseEvent } from 'types/interfaces';

export type TextSize = keyof typeof sizesMap;

export interface JitTextProps extends TypographyProps {
  text?: string | number;
  fallbackText?: string | number;
  params?: IDictionary<string | number | undefined | false | null>;
  // components are used to replace parts of the text with custom react components
  // https://react.i18next.com/latest/trans-component#using-with-react-components
  components?: { [tagName: string]: React.ReactNode };
  size?: TextSize | string;
  bold?: boolean;
  muted?: boolean;
  onClick?: (e: IMouseEvent) => void;
  addUnderlineOnHover?: boolean;
  'data-testid'?: string
  color?: string;
  noWrap?: boolean;
  horizontalAlign?: 'start' | 'center' | 'end';
  overflowHiddenEllipsis?: boolean;
  lineHeight?: number;
  withUnderline?: boolean;
  display?: string;
}

export const JitText: FC<JitTextProps> = ({
  text,
  fallbackText,
  lineHeight = 1.5,
  params = {},
  components = {},
  muted,
  size = 'm',
  bold,
  className,
  onClick,
  addUnderlineOnHover,
  color,
  noWrap,
  horizontalAlign = 'left',
  overflowHiddenEllipsis,
  withUnderline,
  display,
  ...props
}) => {
  if (text === undefined || text === null || text === '') return null;

  const textColor = color || (muted ? colors.lightGray : colors.white);

  const classNames = [className];
  if (addUnderlineOnHover) {
    classNames.push(styles.clickable);
  }

  const displayedText = i18n.t(text.toString(), fallbackText?.toString(), params);
  return (
    <Typography
      align='left'
      alignSelf={horizontalAlign}
      className={classNames.join(' ')}
      color={textColor}
      data-testid={props['data-testid'] || displayedText}
      display={display}
      lineHeight={lineHeight}
      onClick={onClick}
      sx={{
        fontWeight: bold ? 'bold' : 'inherit',
        overflow: overflowHiddenEllipsis ? 'hidden' : 'visible',
        textOverflow: overflowHiddenEllipsis ? 'ellipsis' : undefined,
        whiteSpace: noWrap ? 'nowrap' : 'normal',
        cursor: onClick ? 'pointer' : undefined,
        textDecoration: withUnderline ? 'underline' : undefined,
        fontSize: sizesMap[size as TextSize] || size,
      }}
      {...props}
    >
      <Trans components={components} def defaults={fallbackText?.toString()} i18nKey={text.toString()} values={params} />

    </Typography>
  );
};
