import { useCallback } from 'react';

import { QuickstartGuideData } from 'pages/QuickStartGuide/types';
import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { Vendor } from 'types/enums';
import { PreferencesScope } from 'types/enums/PreferencesScope';
import { IAsset, IInstallation, IIntegration, IPreferences } from 'types/interfaces';
import { ITenantUser, IUserVendorServerRequest } from 'types/interfaces/IUserVendor';
import { UpdateCheckStatus } from 'types/interfaces/TenantService/ICheck';
import {
  IGenerateInvitationTokenRequest,
  IGenerateInvitationTokenResponse,
} from 'types/interfaces/TenantService/IGenerateInvitationToken';
import {
  IPartialUpdateOnboardingDetailsRequest,
  IPartialUpdateOnboardingDetailsResponse,
} from 'types/interfaces/TenantService/IOnboardingDetails';
import { ISetCentralizedRepoRequest } from 'types/interfaces/TenantService/ISetCentralizedRepo';
import { ISignupRequest, ISignupResponse } from 'types/interfaces/TenantService/ISignup';
import { IUpdateUserRequest } from 'types/interfaces/TenantService/IUpdateUserRequest';
import {
  IValidateCentralizedRepoRequest,
  IValidateCentralizedRepoResponse,
} from 'types/interfaces/TenantService/IValidateCentralizedRepo';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useTenantService = () => {
  const { client } = useClient();

  const getAllInstallations = useCallback(() => {
    const url = getApiUrls.tenantService.getAllInstallations();
    return client.get<IInstallation[]>({
      url,
      allowedStatuses: [200, 404],
    });
  }, [client]);

  const fetchIntegrations = useCallback(() => {
    const url = getApiUrls.tenantService.fetchIntegrations();
    return client.get<IIntegration[]>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  const patchPreferences = useCallback(<T extends object>(preferenceType: string, preferences: T, scope: PreferencesScope) => {
    const scopedPreferences = {
      ...preferences,
      scope,
    };

    const url = getApiUrls.tenantService.patchPreferences(preferenceType);
    return client.patch<T>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: scopedPreferences,
      },
    });
  }, [client]);

  const fetchPreferences = useCallback(() => {
    const scope = PreferencesScope.MERGED;
    const queryParams = { scope };
    const url = 'tenant/preferences';
    return client.get<IPreferences>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params: queryParams,
      },
    });
  }, [client]);

  const getTenantUsers = useCallback(() => {
    const url = 'tenant/users';
    return client.get<ITenantUser[]>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  const createUserVendor = useCallback((request: IUserVendorServerRequest) => {
    const url = 'tenant/user/uservendor';
    return client.post<IUserVendorServerRequest>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        body: request,
      },
    });
  }, [client]);

  const getUserVendorByVendor = useCallback((vendor: string, userId?: string) => {
    const url = getApiUrls.tenantService.getUserVendorByVendor(vendor);

    let queryParams = {};
    if (userId) {
      queryParams = {
        ...queryParams,
        user_id: userId,
      };
    }
    return client.get<IUserVendorServerRequest[]>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params: queryParams,
      },
    });
  }, [client]);

  const updateUser = useCallback((body: IUpdateUserRequest) => {
    const url = 'tenant/user/update';
    return client.post({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: body,
      },
    });
  }, [client]);

  const signUp = useCallback((body: ISignupRequest) => {
    const url = 'tenant/sign-up';
    return client.post<ISignupResponse>({
      url,
      allowedStatuses: [201, 200, 403],
      requestConfig: {
        body,
      },
    });
  }, [client]);

  const setCentralizedRepo = useCallback((githubInstallation: IInstallation, centralizedRepoAsset: IAsset) => {
    const request: ISetCentralizedRepoRequest = {
      vendor: Vendor.Github,
      app_id: githubInstallation.app_id,
      installation_id: githubInstallation.installation_id,
      centralized_repo_asset_id: centralizedRepoAsset.asset_id,
      centralized_repo_asset_name: centralizedRepoAsset.asset_name,
    };
    const url = getApiUrls.tenantService.setCentralizedRepo(request.vendor, request.app_id, request.installation_id);
    return client.put({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        data: {
          centralized_repo_asset_id: request.centralized_repo_asset_id,
          centralized_repo_asset_name: request.centralized_repo_asset_name,
        },
      },
    });
  }, [client]);

  const validateCentralizedRepo = useCallback((githubInstallation: IInstallation, centralizedRepoAsset: IAsset) => {
    const request: IValidateCentralizedRepoRequest = {
      vendor: Vendor.Github,
      app_id: githubInstallation.app_id,
      installation_id: githubInstallation.installation_id,
      centralized_repo_asset_id: centralizedRepoAsset.asset_id,
      centralized_repo_asset_name: centralizedRepoAsset.asset_name,
    };
    const url = getApiUrls.tenantService.validateCentralizedRepo(request.vendor, request.app_id, request.installation_id);
    return client.put<IValidateCentralizedRepoResponse>({
      url,
      allowedStatuses: [200, 400],
      requestConfig: {
        data: {
          centralized_repo_asset_id: request.centralized_repo_asset_id,
          centralized_repo_asset_name: request.centralized_repo_asset_name,
        },
      },
    });
  }, [client]);

  const partialUpdateOnboardingDetails = useCallback((request: IPartialUpdateOnboardingDetailsRequest) => {
    const url = getApiUrls.tenantService.partialUpdateOnboardingDetails();
    return client.patch<IPartialUpdateOnboardingDetailsResponse>({
      url,
      allowedStatuses: [200, 404, 400],
      requestConfig: {
        data: request,
      },
    });
  }, [client]);

  const generateInvitationToken = useCallback((request: IGenerateInvitationTokenRequest) => {
    const url = getApiUrls.tenantService.generateInvitationToken();
    return client.post<IGenerateInvitationTokenResponse>({
      url,
      allowedStatuses: [201],
      requestConfig: {
        data: request,
      },
    });
  }, [client]);

  const fetchQuickstartGuideData = useCallback(async () => {
    const url = getApiUrls.quickstartGuide.getChecks();
    const response = await client.get<QuickstartGuideData>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200 && response?.data) {
      return camelizeSnakeCaseKeys(response.data) as QuickstartGuideData;
    }

    return undefined;
  }, [client]);

  const updateCheckStatus = useCallback(async (request: UpdateCheckStatus) => {
    const url = getApiUrls.quickstartGuide.putCheck(request.checkSlug);
    const response = await client.put<QuickstartGuideData>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: {
          is_completed: request.isCompleted,
        },
      },
    });

    if (response?.status === 200 && response?.data) {
      return camelizeSnakeCaseKeys(response.data) as QuickstartGuideData;
    }

    return undefined;
  }, [client]);

  return {
    getAllInstallations,
    fetchIntegrations,
    patchPreferences,
    fetchPreferences,
    getTenantUsers,
    createUserVendor,
    getUserVendorByVendor,
    updateUser,
    signUp,
    setCentralizedRepo,
    validateCentralizedRepo,
    partialUpdateOnboardingDetails,
    generateInvitationToken,
    fetchQuickstartGuideData,
    updateCheckStatus,
  };
};
