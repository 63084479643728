import { CSSProperties, FC } from 'react';
import { Row } from 'react-table';

import { CellVerticalAlign, DefaultCellVerticalAlign, DefaultTableDesign, TableDesignVariants } from '../../constants';
import { ElementToShowOnHoverProps } from '../JitTableRow/JitTableRow';

import { GroupTableRow } from './GroupTableRow';

import { ISvg, ITableRow } from 'types/interfaces';

export interface GroupedRow {
  rows: Row<ITableRow>[];
  groupText: string;
  groupIcon?: FC<ISvg>;
  groupCta?: JSX.Element;
}

interface Props {
  cellPadding?: string;
  onSelectRow?: (row: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  selectedRow?: ITableRow | null;
  groupedRows: GroupedRow[];
  cellsNumber: number;
  cellVerticalAlign?: CellVerticalAlign;
  tableDesign?: TableDesignVariants;
  ElementToShowOnRowHover?: FC<ElementToShowOnHoverProps>;
  rowHoverStyle?: CSSProperties;
  prepareRow: (row: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const GroupedTableRows: FC<Props> = ({
  cellPadding,
  onSelectRow,
  selectedRow,
  cellsNumber,
  cellVerticalAlign = DefaultCellVerticalAlign,
  tableDesign = DefaultTableDesign,
  prepareRow,
  groupedRows,
  rowHoverStyle,
  ElementToShowOnRowHover,
}) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    { groupedRows?.map((group) => (
      <GroupTableRow
        key={group.groupText}
        cellPadding={cellPadding}
        cellsNumber={cellsNumber}
        cellVerticalAlign={cellVerticalAlign}
        ElementToShowOnRowHover={ElementToShowOnRowHover}
        groupCta={group.groupCta}
        groupIcon={group.groupIcon}
        groupText={group.groupText}
        onSelectRow={onSelectRow}
        prepareRow={prepareRow}
        rowHoverStyle={rowHoverStyle}
        rows={group.rows}
        selectedRow={selectedRow}
        tableDesign={tableDesign}
      />
    ))}
  </>
);
