import styles from './ProgressBarPanel.module.scss';

import { CircleCheck } from 'assets';
import { StyledLinearProgress } from 'components/AppLoadingBar/AppLoadingBar.styles';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

type Props = {
  title: string;
  total: number;
  completed: number;
};

const titleStyle = {
  flex: 1,
  padding: '0 10px',
};

export const ProgressBarPanel = ({ title, total, completed }: Props) => {
  const percentage = (completed / total) * 100;
  const statusText = percentage === 100
    ? 'pages.quickStart.checks.securityPlansProcess.completed.status'
    : 'pages.quickStart.checks.securityPlansProcess.scanning.status';

  return (
    <div className={styles.wrapper} data-testid='progress-bar-panel'>
      <JitIcon color='transparent' data-testid='progress-bar-icon' icon={CircleCheck} />

      <JitText
        bold
        data-testid='progress-bar-panel-title'
        size='s'
        style={titleStyle}
        text={title}
      />

      <div className={styles.barWrapper} data-testid='progress-bar-wrapper'>
        <div className={styles.progressBar} data-testid='progress-bar'>
          <StyledLinearProgress
            data-testid='progress-bar-percentage'
            value={percentage}
            variant='determinate'
          />
        </div>

        <div className={styles.barLegend} data-testid='progress-bar-legend'>
          <JitText color={colors.gray} data-testid='progress-bar-status-text' size='xs' text={statusText} />

          <JitText
            color={colors.gray}
            data-testid='progress-bar-resources-text'
            size='xs'
            text={`Resources: ${completed}/${total}`}
          />
        </div>

      </div>
    </div>
  );
};
