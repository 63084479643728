import { Input } from '@mui/material';
import { t } from 'i18next';
import Markdown from 'markdown-to-jsx';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './PlanItemManualDialog.module.scss';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import {
  ActionButton,
  ActionButtonVariant,
  IPlanItemRequirement,
  IPlanItemRequirements, MenuItemKeyType,
  PlanItemStatus,
} from 'types/interfaces';
import { markdownOptionsProps } from 'utils/constants/markdownOptionsProps';

export const enum Status {
  PASSED = 'passed',
  FAILED = 'failed',
  NOT_APPLICABLE = 'not_applicable',
}

const MenuItems: PlanItemStatus[] = [Status.PASSED, Status.FAILED, Status.NOT_APPLICABLE];

interface Props {
  title: string;
  description: string;
  config: {
    status?: PlanItemStatus;
    note?: string;
    requirements?: IPlanItemRequirements;
  };
  requirementsTemplate?: {
    [key: string]: {
      text: string;
    };
  }
  isOpen: boolean;
  isApplyLoading: boolean;
  onClose: () => void;
  onConfigChange: (config: { status?: string; note: string, requirements?: IPlanItemRequirements }) => void;
}

export const PlanItemManualDialog: FC<Props> = ({
  title,
  description,
  isOpen,
  isApplyLoading,
  onClose,
  config,
  onConfigChange,
  requirementsTemplate,
}) => {
  const { status: initialStatus, note: initialNote, requirements: initialRequirements } = config;
  const isInitialStatusValid = useMemo(() => !!(initialStatus && MenuItems.includes(initialStatus)), [initialStatus]);
  const initialMenuStatus: PlanItemStatus = useMemo(() => (initialStatus && isInitialStatusValid ? initialStatus : Status.PASSED), [initialStatus, isInitialStatusValid]);
  const [status, setStatus] = useState<PlanItemStatus>(initialMenuStatus);
  const [note, setNote] = useState<string>(initialNote || '');
  const initialRequirementsWithDefaultStatus = useMemo(() => Object.fromEntries(Object.keys(requirementsTemplate || {}).map((requirementKey) => [requirementKey, {
    status: initialRequirements?.[requirementKey]?.status || Status.PASSED,
    note: initialRequirements?.[requirementKey]?.note || '',
  }])), [requirementsTemplate, initialRequirements]);
  const [requirements, setRequirements] = useState<{
    [key: string]: IPlanItemRequirement
  }>(initialRequirementsWithDefaultStatus);
  const [isValid, setIsValid] = useState<boolean>(false);
  const menuItems: Partial<MenuItemType>[] = useMemo(() => MenuItems.map((itemKey: string) => ({
    itemKey,
    itemName: `pages.plan.configurationsDialog.manualItemsConfiguration.menuItemsNames.${itemKey}`,
    isSelected: status === itemKey,
  })), [status]);

  const getRequirementsMenuItems = useCallback((requirementKey: string) => MenuItems.map((itemKey: string) => ({
    itemKey,
    itemName: `pages.plan.configurationsDialog.manualItemsConfiguration.requirementsMenuItemsNames.${itemKey}`,
    isSelected: requirements[requirementKey]?.status === itemKey,
  })), [requirements]);

  const menuDisplayText = useMemo(() => menuItems.find((item) => status === item.itemKey)?.itemName || '', [menuItems, status]);
  const getRequirementsMenuDisplayText = useCallback(
    (requirementKey: string) => getRequirementsMenuItems(requirementKey).find((item) => (requirements[requirementKey]?.status || Status.PASSED) === item.itemKey)?.itemName || '',
    [requirements, getRequirementsMenuItems],
  );
  const hasRequirements = useMemo(() => Object.keys(requirementsTemplate || {}).length > 0, [requirementsTemplate]);

  const areRequirementsValid = useCallback(() => Object.keys(requirementsTemplate || {}).every((requirementKey) => {
    const requirement = requirements[requirementKey];
    return requirement?.status && requirement?.note;
  }), [requirements, requirementsTemplate]);

  const validateInput = useCallback(() => {
    const isGeneralNoteValid = hasRequirements || !!note;
    const isStatusValid = hasRequirements || !!status;
    const hasInputFilled = isStatusValid && isGeneralNoteValid && areRequirementsValid();
    setIsValid(hasInputFilled);
  }, [areRequirementsValid, hasRequirements, note, status]);

  useEffect(() => {
    validateInput();
  }, [validateInput]);

  const onNoteChange = useCallback((e) => {
    setNote(e.target.value);
    validateInput();
  }, [setNote, validateInput]);

  const getOnRequirementNoteChange = (requirementKey: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setRequirements((prevRequirements) => ({
      ...prevRequirements,
      [requirementKey]: {
        ...prevRequirements[requirementKey],
        note: e.target.value,
      },
    }));
    validateInput();
  };

  const onStatusChange = useCallback((e) => {
    if (e.itemKey !== status) {
      setStatus(e.itemKey);
      validateInput();
    }
  }, [setStatus, status, validateInput]);

  const getOnRequirementStatusChange = (requirementKey: string) => ({ itemKey }: { itemKey: MenuItemKeyType }) => {
    if (itemKey !== requirements[requirementKey]?.status) {
      setRequirements((prevRequirements) => ({
        ...prevRequirements,
        [requirementKey]: {
          ...prevRequirements[requirementKey],
          status: itemKey as PlanItemStatus,
        },
      }));
      validateInput();
    }
  };

  const onApply = useCallback(async () => {
    const isStatusChanged = hasRequirements ? false : status !== initialStatus;
    const isNoteChanged = note !== initialNote;
    const areRequirementsChanged = hasRequirements ? Object.keys(requirements).some((requirementKey) => {
      const requirement = requirements[requirementKey];
      return requirement?.status !== initialRequirementsWithDefaultStatus?.[requirementKey]?.status || requirement?.note !== initialRequirementsWithDefaultStatus?.[requirementKey]?.note;
    }) : false;
    if (isValid && (isStatusChanged || isNoteChanged || areRequirementsChanged)) {
      await onConfigChange({
        status: hasRequirements ? undefined : status,
        note,
        requirements: hasRequirements ? requirements : undefined,
      });
    }
  }, [hasRequirements, status, initialStatus, note, initialNote, requirements, isValid, initialRequirementsWithDefaultStatus, onConfigChange]);

  const actionButtons: ActionButton[] = [
    {
      label: 'pages.plan.configurationsDialog.cancel',
      variant: ActionButtonVariant.OUTLINED,
      handleClick: onClose,
    },
    {
      label: 'pages.plan.configurationsDialog.apply',
      handleClick: onApply,
      isLoading: isApplyLoading,
      disabled: !isValid,
      tooltip: !isValid ? 'pages.plan.configurationsDialog.manualItemsConfiguration.applyDisabledTooltip' : undefined,
    },
  ];

  const content = (
    <div className={styles.configurationBoxWrapper}>
      <JitText bold color={colors.white} size='l' text={title} />

      <Markdown className={styles.description} {...markdownOptionsProps}>
        {description}
      </Markdown>

      {Object.entries(requirementsTemplate || {}).map(([requirementKey, requirement]) => {
        const onRequirementNoteChange = getOnRequirementNoteChange(requirementKey);
        const onRequirementStatusChange = getOnRequirementStatusChange(requirementKey);
        return (
          <div key={requirementKey} className={styles.inputSection}>
            <div className={styles.inputSection}>
              <JitText
                size='l'
                text={requirement.text}
              />

              <JitDropdownNew
                displayText={getRequirementsMenuDisplayText(requirementKey)}
                menuItems={getRequirementsMenuItems(requirementKey)}
                onMenuItemClick={onRequirementStatusChange}
              />
            </div>

            <div className={styles.inputSection}>
              <JitText color={colors.gray} size='l' text='pages.plan.configurationsDialog.manualItemsConfiguration.note' />

              <Input
                className={styles.inputField}
                data-testid={`note-input-${requirementKey}`}
                disableUnderline
                fullWidth
                margin='dense'
                maxRows={3}
                minRows={3}
                multiline
                name='inputField'
                onChange={onRequirementNoteChange}
                placeholder={t('pages.plan.configurationsDialog.manualItemsConfiguration.addFreeText')}
                sx={{
                  padding: '10px 15px',
                }}
                value={requirements[requirementKey]?.note}
              />
            </div>
          </div>
        );
      })}

      {!hasRequirements && (
        <div className={styles.inputSection}>
          <JitText
            color={colors.gray}
            size='l'
            text='pages.plan.configurationsDialog.manualItemsConfiguration.controlStatus'
          />

          <JitDropdownNew
            displayText={menuDisplayText}
            menuItems={menuItems}
            onMenuItemClick={onStatusChange}
          />
        </div>
      )}

      <div className={styles.inputSection}>
        <JitText
          color={colors.gray}
          size='l'
          text={hasRequirements ? 'pages.plan.configurationsDialog.manualItemsConfiguration.noteWithRequirements' : 'pages.plan.configurationsDialog.manualItemsConfiguration.note'}
        />

        <Input
          className={styles.inputField}
          data-testid='note-input'
          disableUnderline
          fullWidth
          margin='dense'
          maxRows={3}
          minRows={3}
          multiline
          name='inputField'
          onChange={onNoteChange}
          placeholder={t('pages.plan.configurationsDialog.manualItemsConfiguration.addFreeText')}
          sx={{
            padding: '10px 15px',
          }}
          value={note}
        />
      </div>
    </div>
  );

  return (
    <CustomDialog
      actionButtons={actionButtons}
      content={content}
      isOpen={isOpen}
      onClose={onClose}
      title='pages.plan.configurationsDialog.title'
      width='l'
      withDivider
    />
  );
};
