import { FC, useCallback, useMemo, useState, useEffect } from 'react';

import { GraphContext } from './GraphContext';

import { useGraphService } from 'services/GraphService/useGraphService';
import { IPriorityFactorsGroup } from 'types/interfaces/Graph/IPriorityFactor';

export const GraphProvider: FC = ({ children }) => {
  const [priorityFactorsGroups, setPriorityFactorsGroups] = useState<IPriorityFactorsGroup[]>([]);
  const [isLoadingPriorityFactors, setIsLoadingPriorityFactors] = useState(false);
  const { fetchPriorityFactorsGroups } = useGraphService();

  const getPriorityFactors = useCallback(async () => {
    setIsLoadingPriorityFactors(true);

    const response = await fetchPriorityFactorsGroups();
    if (response?.status === 200) {
      setPriorityFactorsGroups(response.data.groups);
    }
    setIsLoadingPriorityFactors(false);
  }, [fetchPriorityFactorsGroups]);

  useEffect(() => {
    if (!priorityFactorsGroups.length && !isLoadingPriorityFactors) {
      getPriorityFactors();
    }
  }, [getPriorityFactors, isLoadingPriorityFactors, priorityFactorsGroups.length]);

  const allPriorityFactors = useMemo(() => priorityFactorsGroups.flatMap((group) => group.factors.map((factor) => ({
    ...factor,
    group,
  }))), [priorityFactorsGroups]);

  const value = useMemo(() => ({
    allPriorityFactors,
    priorityFactorsGroups,
    isLoadingPriorityFactors,
    getPriorityFactors,
  }), [allPriorityFactors, priorityFactorsGroups, isLoadingPriorityFactors, getPriorityFactors]);

  return (
    <GraphContext.Provider value={value}>
      {children}
    </GraphContext.Provider>
  );
};
