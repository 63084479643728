import { FC, useState, useEffect, useCallback } from 'react';

import { JitFilters } from 'components/JitFilters/JitFilters';
import { i18n } from 'locale/i18n';
import { useGraphService } from 'services/GraphService/useGraphService';
import { AssetType } from 'types/enums';
import { IFilter, IFilterType } from 'types/interfaces';

export const ResourcesTableFilters: FC = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);

  const { fetchPriorityFactorsGroups } = useGraphService();

  const getTeams = async () => [
    { // TODO: Change this to the real API Req
      value: 'Team Context',
      displayText: 'Team Context',
    },
  ];
  const getFactorsOptions = useCallback(async () => {
    const response = await fetchPriorityFactorsGroups();
    if (!response?.data?.groups) return [];
    const factors = response.data.groups.map((group) => group.factors).flat();
    return factors.map((factor) => ({
      value: factor.key,
      displayText: factor.displayName,
    }));
  }, [fetchPriorityFactorsGroups]);

  useEffect(() => {
    if (isLoadingFilters) {
      const results = [
        getTeams(),
        getFactorsOptions(),
      ];
      Promise.all(results).then(([teamsOptions, factorsOptions]) => {
        setFilters([
          {
            entityKey: 'priority_factors',
            displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.priorityFactors'),
            type: IFilterType.MULTI_SELECT,
            selectedValue: [],
            valueOptions: factorsOptions,
          },
          {
            entityKey: 'asset_type',
            displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.type'),
            type: IFilterType.MULTI_SELECT,
            selectedValue: [],
            valueOptions: Object.values(AssetType).map((value) => ({
              value,
              displayText: i18n.t(`assetType.${value}`),
            })),
          },
          {
            entityKey: 'teams',
            displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.teams'),
            type: IFilterType.MULTI_SELECT,
            selectedValue: [],
            valueOptions: teamsOptions,
          },
        ]);

        setIsLoadingFilters(false);
      });
    }
  }, [fetchPriorityFactorsGroups, getFactorsOptions, isLoadingFilters]);

  const updateFilter = (updatedFilter: IFilter) => {
    setFilters((prevFilters) => prevFilters.map((filter) => (filter.entityKey === updatedFilter.entityKey ? updatedFilter : filter)));
  };

  return (
    <JitFilters filters={filters} shouldCloseOnItemClick={false} updateFilter={updateFilter} />
  );
};
