import { FC } from 'react';

import styles from './BaseCard.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  title: string;
}

export const BaseCard: FC<Props> = ({ title, children }) => (
  <div className={styles.wrapper}>
    <JitText align='center' muted text={title} />

    {children}
  </div>
);
