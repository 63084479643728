import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { defaultSortColumns } from 'context/FindingsContext/constants';
import { SortDirections } from 'types/enums/SortDirections';
import { ISortColumn, ISortColumns, ServerFindingKeys } from 'types/interfaces';

export const useColumnSorting = () => {
  const [sortColumns, setSortColumns] = useState<ISortColumns>(defaultSortColumns);
  const { useKnowledgeGraphSort } = useFlags();

  const getColumn = useCallback((columnKey: string) => sortColumns.columns.find((c) => c.sortKey === columnKey), [sortColumns]);

  const toggleColumnSort = useCallback((columnKey: string) => {
    const column = sortColumns.columns.find(({ sortKey }) => sortKey === columnKey);
    if (!column) {
      return;
    }
    const updatedColumn: ISortColumn = {
      ...column,
      direction: column.direction === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC,
    };
    const updatedColumns = sortColumns.columns.map((col) => (col.sortKey === columnKey ? { ...updatedColumn } : { ...col }));
    setSortColumns({
      columns: updatedColumns,
      activeColumnKey: columnKey,
    });
  }, [sortColumns.columns]);

  useEffect(() => {
    const defaultSortColumnKey = useKnowledgeGraphSort ? ServerFindingKeys.priority_score : ServerFindingKeys.created_at;
    setSortColumns((prevState) => ({
      ...prevState,
      activeColumnKey: defaultSortColumnKey,
    }));
  }, [useKnowledgeGraphSort]);

  const activeColumn = useMemo(() => getColumn(sortColumns.activeColumnKey), [getColumn, sortColumns.activeColumnKey]);

  return {
    sortColumns,
    getColumn,
    toggleColumnSort,
    activeColumn,
  };
};
