import { IFindingPriorityContext, ManualFactors, TicketFindingServer } from './IFinding';

import { Severity } from 'types/enums';
import { IFixSuggestion } from 'types/interfaces/Actions';

export const enum ServerFindingKeys {
  id = 'id',
  test_name = 'test_name',
  status = 'resolution',
  last_detected_at = 'last_detected_at',
  issue_severity = 'issue_severity',
  issue_text = 'issue_text',
  job_name = 'job_name',
  control_name = 'control_name',
  asset_domain = 'asset_domain',
  asset_name = 'asset_name',
  references = 'references',
  location = 'location',
  location_text = 'location_text',
  code_attributes = 'code_attributes',
  cloud_attributes = 'cloud_attributes',
  asset_type = 'asset_type',
  vendor = 'vendor',
  created_at = 'created_at',
  asset_id = 'asset_id',
  fingerprint = 'fingerprint',
  plan_layer = 'plan_layer',
  vulnerability_type = 'vulnerability_type',
  app_attributes = 'app_attributes',
  pipeline_status = 'pipeline_resolution',
  plan_item = 'plan_item',
  team = 'team',
  ignored = 'ignored',
  ignore_rules_ids = 'ignore_rules_ids',
  asset_priority_score = 'asset_priority_score',
  priority_score = 'priority_score',
  priority_factors = 'priority_factors',
  priority_context = 'priority_context',
  original_priority_context = 'original_priority_context',
  exposure = 'exposure',
  environment = 'environment',
  tickets = 'tickets',
  manual_factors = 'manual_factors',
  fix_pr_url = 'fix_pr_url',
  fix_suggestion = 'fix_suggestion',
  cwes = 'cwes',
  cves = 'cves',
  test_id = 'test_id',
}

export interface IFindingServer {
  [ServerFindingKeys.id]: string;
  [ServerFindingKeys.test_name]: string;
  [ServerFindingKeys.status]: string;
  [ServerFindingKeys.last_detected_at]?: string;
  [ServerFindingKeys.issue_severity]: Severity;
  [ServerFindingKeys.issue_text]: string;
  [ServerFindingKeys.job_name]: string;
  [ServerFindingKeys.control_name]: string;
  [ServerFindingKeys.asset_domain]: string;
  [ServerFindingKeys.asset_name]: string;
  [ServerFindingKeys.test_id]: string;
  [ServerFindingKeys.references]: IFindingServerReference[];
  [ServerFindingKeys.location]?: string;
  [ServerFindingKeys.location_text]?: string;
  [ServerFindingKeys.code_attributes]?: IFindingServerCodeAttributes;
  [ServerFindingKeys.cloud_attributes]?: IFindingServerCloudAttributes;
  [ServerFindingKeys.asset_type]: string;
  [ServerFindingKeys.vendor]: string;
  [ServerFindingKeys.created_at]?: string;
  [ServerFindingKeys.asset_id]: string;
  [ServerFindingKeys.fingerprint]: string;
  [ServerFindingKeys.plan_layer]: string;
  [ServerFindingKeys.vulnerability_type]: string;
  [ServerFindingKeys.app_attributes]?: IFindingServerAppAttributes;
  [ServerFindingKeys.pipeline_status]?: string;
  [ServerFindingKeys.ignored]: boolean;
  [ServerFindingKeys.ignore_rules_ids]: string[];
  [ServerFindingKeys.team]?: string;
  [ServerFindingKeys.asset_priority_score]?: number;
  [ServerFindingKeys.priority_score]?: number;
  [ServerFindingKeys.priority_factors]?: string[];
  [ServerFindingKeys.priority_context]?: IFindingPriorityContext;
  [ServerFindingKeys.original_priority_context]?: IFindingPriorityContext;
  [ServerFindingKeys.exposure]?: string;
  [ServerFindingKeys.environment]?: string;
  [ServerFindingKeys.tickets]?: TicketFindingServer[];
  [ServerFindingKeys.manual_factors]: ManualFactors;
  [ServerFindingKeys.fix_pr_url]?: string;
  [ServerFindingKeys.fix_suggestion]?: IFixSuggestion;
  [ServerFindingKeys.fix_suggestion]?: IFixSuggestion;
  [ServerFindingKeys.cwes]?: string[];
  [ServerFindingKeys.cves]?: string[];
}

interface IFindingServerCodeAttributes {
  filename: string;
  line_range: string;
  code_snippet: string;
  branch: string;
}

export interface IFindingServerCloudAttributes {
  account_id: string;
  account_name: string;
  region: string;
  cloud_service_name: string;
}

export interface IFindingServerAppAttributes {
  target_url: string;
  request_header: string;
  response_header: string;
  param: string;
  path: string;
  method: string;
  solution: string;
  cweid: string;
  tags?: object;
}

interface IFindingServerReference {
  name: string;
  url: string;
}
