import { FC } from 'react';

import styles from './TotalResourcesCount.module.scss';

import { JitText } from 'components/JitText/JitText';

export const TotalResourcesCount: FC = () => {
  const isLoadingAssetsCount = false;
  const assetsCount = 101;
  return (

    <div className={styles.resourcesCountWrapper}>
      {!isLoadingAssetsCount && <JitText bold muted text={`${assetsCount} Resources`} />}
    </div>
  );
};
