import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { logError } from 'services/logger/logger';
import { useClient } from 'services/useClient';
import { IInstallation } from 'types/interfaces';

const WAIT_BETWEEN_RETRIES = 3000;
const MAX_RETRIES = 3;

export interface IGroup {
  id: string,
  name: string,
  path: string,
}

export interface ICreateInstallation {
  group_id: string;
  group_name: string;
  group_slug: string;
}

export interface IProject {
  id: string;
  name: string;
  path_with_namespace: string;
}
interface FetchProjectsParams {
  search?: string;
  page: number;
  per_page: number;
}

export const allProjectCoverageType = 'all' as const;
export const selectedProjectCoverageType = 'selected' as const;

export interface IUpdateInstallationProjects {
  project_coverage_type: typeof allProjectCoverageType | typeof selectedProjectCoverageType;
  projects?: {
    project_id: string;
    project_name: string;
  }[];
}

const wait = (ms: number): Promise<void> => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export interface FetchGroupsParams {
  page: number;
  per_page: number;
  search?: string;
}

export const useGitlabService = () => {
  const { client } = useClient();

  const fetchGroups = useCallback(async (params: FetchGroupsParams) => {
    const url = getApiUrls.gitlabService.getGroups();
    let retryCount = 0;

    while (retryCount <= WAIT_BETWEEN_RETRIES) {
      // eslint-disable-next-line no-await-in-loop
      const response = await client.get<{ groups: IGroup[] }>({
        url,
        allowedStatuses: [200, 425],
        requestConfig: {
          params,
        },
      });

      if (response?.status === 425) {
        if (retryCount === MAX_RETRIES) {
          logError(`Failed to fetch groups from Gitlab after ${MAX_RETRIES} retries`);
          return undefined;
        }
        // eslint-disable-next-line no-await-in-loop
        await wait(WAIT_BETWEEN_RETRIES);
        retryCount += 1;
      } else {
        return response?.data.groups;
      }
    }

    logError(`Failed to fetch groups from Gitlab after ${MAX_RETRIES} retries`);
    return undefined;
  }, [client]);

  const createInstallation = useCallback(async (body: ICreateInstallation) => {
    const url = getApiUrls.gitlabService.createInstallation();
    return client.post<IInstallation>({
      url,
      allowedStatuses: [201, 409],
      requestConfig: {
        data: body,
      },
    });
  }, [client]);

  const fetchProjects = useCallback(async (groupId: string, params: FetchProjectsParams) => {
    const url = getApiUrls.gitlabService.getProjects(groupId);
    const response = await client.get<{ projects: IProject[] }>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });

    return response?.data.projects;
  }, [client]);

  const updateInstallationProjects = useCallback(async (installationId: string, body: IUpdateInstallationProjects) => {
    const url = getApiUrls.gitlabService.updateInstallationProjects(installationId);
    return client.post<IInstallation>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: body,
      },
    });
  }, [client]);

  return {
    fetchGroups,
    createInstallation,
    fetchProjects,
    updateInstallationProjects,
  };
};
