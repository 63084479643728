import { useAuthActions, useAuthUser, useTenantsState } from '@frontegg/react';
import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Row } from './components/Row/Row';
import { TenantRow } from './components/TenantRow/TenantRow';
import { UserDetails } from './components/UserDetails/UserDetails';
import styles from './SelectTenantPopper.module.scss';

import { Check, Logout } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { Separator } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/Separator/Separator';

interface Props {
  shouldShowArrow?: boolean;
  handleLogout: () => void;
  shouldShowLogout: boolean;
}

// ts-prune-ignore-next
export const SelectTenantPopper: FC<Props> = ({ shouldShowArrow, handleLogout, shouldShowLogout }) => {
  const frontEggUser = useAuthUser();
  const { tenants } = useTenantsState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { switchTenant } = useAuthActions();

  const sortedTenants = [...tenants];
  sortedTenants.sort((a, b) => a.name.localeCompare(b.name));

  const currentTenant = sortedTenants.find((tenant) => tenant.tenantId === frontEggUser.tenantId);

  const handleSwitchTenant = (tenantId: string) => {
    navigate('/');
    queryClient.removeQueries();
    localStorage.clear();
    switchTenant({ tenantId });
  };

  return (
    <div className={styles.contentWrapper} data-testid='SelectTenantPopper'>
      <UserDetails tenant={currentTenant} />

      <Separator />

      <div className={styles.tenantsList} data-testid='tenantsList'>
        {sortedTenants.map(({ tenantId, name }, index) => (
          <TenantRow
            key={tenantId}
            endAdornment={frontEggUser.tenantId === tenantId ? (<JitIcon icon={Check} size={10} />) : undefined}
            isLast={!shouldShowLogout && index === sortedTenants.length - 1}
            onClick={() => handleSwitchTenant(tenantId)}
            shouldShowArrow={shouldShowArrow}
            tenantName={name}
          />
        ))}

      </div>

      {shouldShowLogout && (
        <Row
          icon={<JitIcon icon={Logout} />}
          isLast
          onSelect={handleLogout}
          shouldShowArrow={shouldShowArrow}
          text='Logout'
        />
      )}

    </div>
  );
};
