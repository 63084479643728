import { useFlags } from 'launchdarkly-react-client-sdk';
import { groupBy, isEmpty } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';

import { PlanTemplatesContext } from './PlanTemplatesContext';

import { logError } from 'services/logger/logger';
import { usePlanService } from 'services/PlanService/usePlanService';
import { getIControlOptionsFromWorkflowTemplates } from 'services/PlanService/utils/workflowTemplates';
import {
  IControlOption,
  IFilter,
  IFilterType,
  IPlanFilterEntity,
  IPlanItemTemplate,
  IPlanTemplate,
  PlanItemAggregatedStatus,
  PlanItemState,
} from 'types/interfaces';

export const PlanTemplatesProvider: FC = ({ children }) => {
  const { uiHidePlanItemStatusBadge } = useFlags();
  const { getAllPlanItemTemplates, getAllPlanTemplates } = usePlanService();

  const [planItemTemplates, setPlanItemTemplates] = useState<IPlanItemTemplate[]>([]);
  const [planItemTemplatesIsLoading, setPlanItemTemplatesIsLoading] = useState<boolean>(false);

  const [controlOptions, setControlOptions] = useState<{ [key: string]: IControlOption[] }>({});
  const [isControlOptionsLoading, setIsControlOptionsLoading] = useState<boolean>(false);

  const [planTemplates, setPlanTemplates] = useState<IPlanTemplate[]>([]);
  const [isPlanTemplatesLoading, setIsPlanTemplatesLoading] = useState<boolean>(false);

  const fetchPlanTemplates = useCallback(async () => {
    setIsPlanTemplatesLoading(true);
    const planTemplatesRes = await getAllPlanTemplates() || [];
    setPlanTemplates(planTemplatesRes);
    setIsPlanTemplatesLoading(false);
  }, [getAllPlanTemplates]);

  const fetchControls = useCallback(async () => {
    setIsControlOptionsLoading(true);
    const controlsRes = await getIControlOptionsFromWorkflowTemplates() || [];
    const controlsByItemId = groupBy(controlsRes.filter((controlOption) => controlOption.item_slug), 'item_slug');
    setControlOptions(controlsByItemId);
    setIsControlOptionsLoading(false);
  }, []);

  const fetchPlanItemTemplates = useCallback(async () => {
    setPlanItemTemplatesIsLoading(true);
    const planItemTemplatesRes = await getAllPlanItemTemplates() || [];
    if (!planItemTemplatesRes) {
      logError('Error while fetching plan item templates');
      return;
    }
    setPlanItemTemplates(planItemTemplatesRes);
    setPlanItemTemplatesIsLoading(false);
  }, [getAllPlanItemTemplates]);

  const planItemsTemplates = useMemo(() => {
    if (isEmpty(controlOptions) || isEmpty(planItemTemplates)) return [];
    return planItemTemplates.map((planItem) => ({
      ...planItem,
      controls: controlOptions[planItem.slug],
      is_scheduled: controlOptions[planItem.slug]?.some((controlOption: IControlOption) => controlOption.is_scheduled),
    }));
  }, [controlOptions, planItemTemplates]);

  const getPlanTemplateBySlug = useCallback((slug: string) => planTemplates?.find((planTemplate) => planTemplate.slug === slug), [planTemplates]);
  const getPlanItemTemplateBySlug = useCallback((slug: string) => planItemsTemplates?.find((item) => item.slug === slug), [planItemsTemplates]);

  const enabledPlanTemplates = useMemo(() => [...planTemplates.filter((planTemplate) => planTemplate.isEnabled)].sort((a, b) => a.slug.localeCompare(b.slug)), [planTemplates]);

  const isLoading = useMemo(
    () => isControlOptionsLoading || isPlanTemplatesLoading || planItemTemplatesIsLoading,
    [isControlOptionsLoading, isPlanTemplatesLoading, planItemTemplatesIsLoading],
  );

  const planItemsFilters: IFilter[] = useMemo(() => [
    {
      entityKey: IPlanFilterEntity.STATE,
      displayText: 'State',
      type: IFilterType.SINGLE_SELECT,
      width: '205px',
      valueOptions: [
        {
          displayText: 'pages.plan.planFilters.allStates',
          value: '',
        }, {
          displayText: 'pages.plan.planItem.activated',
          value: PlanItemState.ACTIVATED,
        }, {
          displayText: 'pages.plan.planItem.inactive',
          value: PlanItemState.INACTIVE,
        },
      ],
    },
    ...(uiHidePlanItemStatusBadge ? [] : [
      {
        entityKey: IPlanFilterEntity.STATUS,
        displayText: 'Status',
        type: IFilterType.SINGLE_SELECT,
        width: '205px',
        valueOptions: [
          {
            displayText: 'pages.plan.planFilters.allStatuses',
            value: '',
          }, {
            displayText: 'pages.plan.planItem.passed',
            value: PlanItemAggregatedStatus.SUCCESS,
          }, {
            displayText: 'pages.plan.planItem.failed',
            value: PlanItemAggregatedStatus.FAILURE,
          }, {
            displayText: 'pages.plan.planItem.error',
            value: PlanItemAggregatedStatus.ERROR,
          },
          {
            displayText: 'pages.plan.planItem.pending',
            value: PlanItemAggregatedStatus.PENDING,
          },
        ],
      }]),
  ], [uiHidePlanItemStatusBadge]);

  const value = useMemo(() => ({
    planItemsTemplates,
    planTemplates,
    enabledPlanTemplates,
    isLoading,
    planItemsFilters,
    fetchPlanItemTemplates,
    fetchControls,
    fetchPlanTemplates,
    getPlanItemTemplateBySlug,
    getPlanTemplateBySlug,
  }), [
    planItemsTemplates, planTemplates,
    enabledPlanTemplates, isLoading, planItemsFilters,
    fetchPlanItemTemplates, fetchControls,
    fetchPlanTemplates, getPlanItemTemplateBySlug, getPlanTemplateBySlug,
  ]);

  return (
    <PlanTemplatesContext.Provider value={value}>
      {children}
    </PlanTemplatesContext.Provider>
  );
};
