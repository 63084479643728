import { FC, useMemo, useState } from 'react';

import { SlackContext } from 'context/SlackContext';
import { ISlackServiceChannelResponse, ISlackUserResponse } from 'types/interfaces/Slack';

export const SlackProvider: FC = ({ children }) => {
  const [isBeingUninstalled, setIsBeingUninstalled] = useState<boolean>(false);
  const [slackUsers, setSlackUsers] = useState<ISlackUserResponse[] | undefined>([]);
  const [slackChannels, setSlackChannels] = useState<ISlackServiceChannelResponse[] | undefined>([]);
  const [isSlackChannelsLoading, setIsSlackChannelsLoading] = useState<boolean>(false);

  const value = useMemo(() => ({
    isBeingUninstalled,
    setIsBeingUninstalled,
    slackUsers,
    setSlackUsers,
    slackChannels,
    setSlackChannels,
    isSlackChannelsLoading,
    setIsSlackChannelsLoading,
  }), [isBeingUninstalled, isSlackChannelsLoading, slackChannels, slackUsers]);

  return (
    <SlackContext.Provider value={value}>
      {children}
    </SlackContext.Provider>
  );
};
