import { FC } from 'react';

import { SlackWhite } from 'assets';
import styles from 'components/JitDialogs/SlackIntegrationDialog/components/SlackUserNotifications/SlackUserNotifications.module.scss';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useTenantService } from 'services/TenantService/useTenantService';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { MenuItemKeyType } from 'types/interfaces';
import { ITenantUser, IUserVendorServerRequest } from 'types/interfaces/IUserVendor';
import { ISlackUserResponse } from 'types/interfaces/Slack';

interface Props {
  slackUsers: ISlackUserResponse[],
  slackUsersVendors: IUserVendorServerRequest[],
  setSlackUsersVendors: (slackUsers: IUserVendorServerRequest[]) => void,
  tenantUser: ITenantUser
  selected?: IUserVendorServerRequest,
}

export const SlackUserNotifications: FC<Props> = ({
  slackUsers,
  slackUsersVendors,
  setSlackUsersVendors,
  selected,
  tenantUser,
}) => {
  const getSlackUserName = (slackUser: ISlackUserResponse) => (slackUser.real_name ? slackUser.real_name : slackUser.name);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { createUserVendor } = useTenantService();
  const slackUserIdHashMap: Record<string, ISlackUserResponse> = slackUsers.reduce((acc, slackUser) => {
    acc[slackUser.id] = slackUser;
    return acc;
  }, {} as Record<string, ISlackUserResponse>);

  const setUserVendor = async (userId: string, slackUserId: string) => {
    const request: IUserVendorServerRequest = {
      user_vendor_id: slackUserId,
      user_id: userId,
      vendor: Vendor.SLACK,
      username: getSlackUserName(slackUserIdHashMap[slackUserId]),
    };
    setSlackUsersVendors([...slackUsersVendors.filter((u) => u.user_id !== userId), request]);
    await createUserVendor(request);
  };
  const slackUsersMenuItems: Partial<MenuItemType>[] = Object.values(slackUserIdHashMap).map((slackUser) => ({
    itemKey: slackUser.id,
    itemName: getSlackUserName(slackUser),
    isSelected: getSlackUserName(slackUser) === selected?.username,
  })).sort((a, b) => a.itemName.localeCompare(b.itemName));

  const onMenuItemClick = ({ itemKey }: { itemKey: MenuItemKeyType }) => {
    setUserVendor(tenantUser.id, itemKey as string);
    sendAnalyticsEvent({ action: 'slack-user-selected' });
  };

  const isDropDownLoading = selected ? slackUsersMenuItems.length === 1 : slackUsers.length === 0;

  return (
    <div className={styles.inLine} data-testid='slackUserNotifications'>
      <JitText bold className={styles.stickToTheLeft} data-testid='tenantUserName' text={tenantUser.name} />

      <JitText
        className={styles.stickToTheMiddle}
        color={colors.lightGray}
        data-testid='tenantUserEmail'
        text={tenantUser.email}
      />

      <div className={`${styles.inLine} ${styles.stickToTheRight}`}>
        <JitIcon icon={SlackWhite} size={12} style={{ marginRight: '8px' }} />

        <JitDropdownNew
          defaultDisplayText='dialogs.integration.slack.users.defaultDropDown'
          displayText={selected?.username || selected?.user_vendor_id}
          isLoading={isDropDownLoading}
          menuItems={slackUsersMenuItems}
          onMenuItemClick={onMenuItemClick}
          width='188px'
        />
      </div>
    </div>
  );
};
