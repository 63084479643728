import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useDeploymentConfigurationStateManager } from '../Deployment/hooks/useDeploymentConfigurationStateManager';
import { ConfigurationsCheckboxField } from '../Zap/components/ConfigurationsCheckboxField';

import { TriggetConfigurationInput } from './components/TriggerConfigurationInput/TriggerConfigurationInput';
import styles from './ConfigurationTrigger.module.scss';
import { ENVIRONMENT_CONFIGURATION_KEY } from './constants';
import { GetUpdatedConfigurationAndPlanItemType, useEditConfigurations } from './hooks/useEditConfigurations';
import { getPlanItemTriggerConfiguration } from './utils';

import { OpenInNewTab } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { ApplicationTypes, IConfigurations } from 'types/interfaces';

interface Props {
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
  planItemSlug: string;
  onIsScanOnDeploymentCheckedChange?: (isScanOnDeploymentChecked: boolean) => void;
  applicationType: ApplicationTypes;
  getUpdatedConfigurationAndPlanItem?: GetUpdatedConfigurationAndPlanItemType;
}

export const ConfigurationTrigger: FC<Props> = ({
  setIsDoneStep, planItemSlug, applicationType, getUpdatedConfigurationAndPlanItem, onIsScanOnDeploymentCheckedChange = () => {},
}) => {
  const { fetchedConfigurations } = useConfigurationsContext();
  const { handleOverrideChange, configurations, setConfigurations } = useDeploymentConfigurationStateManager({});
  const { editConfigurations } = useEditConfigurations(planItemSlug, setConfigurations, applicationType, getUpdatedConfigurationAndPlanItem);
  const [environmentInput, setEnvironmentInput] = useState('');
  const [isEnvironmentInputDisabled, setIsEnvironmentInputDisabled] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);

  const currentPlanItemConfiguration = getPlanItemTriggerConfiguration(configurations, planItemSlug, applicationType);
  const [isScanOnDeploymentChecked, setIsScanOnDeploymentChecked] = useState(!!currentPlanItemConfiguration);

  const getEnvironment = useCallback((baseConfigurations?: IConfigurations) => baseConfigurations?.deployment?.environments?.at(0) || '', []);

  // The environment list has only one environment. In future we will change the interface and handle multi environments.
  const fetchedEnvironment = useMemo(() => getEnvironment(fetchedConfigurations), [fetchedConfigurations, getEnvironment]);

  const environment = useMemo(() => getEnvironment(configurations), [configurations, getEnvironment]);

  const isUserChangedTheExistsEnvironment = useMemo(() => environmentInput !== fetchedEnvironment, [environmentInput, fetchedEnvironment]);
  const isEnvironmentSet = useMemo(
    () => environmentInput && (isEnvironmentInputDisabled || isUserChangedTheExistsEnvironment),
    [environmentInput, isEnvironmentInputDisabled, isUserChangedTheExistsEnvironment],
  );

  const handleEnvironmentInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEditClicked) {
      handleOverrideChange(ENVIRONMENT_CONFIGURATION_KEY, [event.target.value]);
    }
    setEnvironmentInput(event.target.value);
  };

  useEffect(() => {
    setEnvironmentInput(environment || '');
    setIsEnvironmentInputDisabled(!!fetchedEnvironment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedEnvironment]);

  const isDoneStep = useMemo(() => !!(isEnvironmentSet || !isScanOnDeploymentChecked) && !isEditClicked, [isEditClicked,
    isEnvironmentSet,
    isScanOnDeploymentChecked]);

  useEffect(() => {
    setIsDoneStep(isDoneStep);
  }, [isDoneStep, setIsDoneStep]);

  useEffect(() => {
    if (isDoneStep) {
      editConfigurations(configurations, isScanOnDeploymentChecked);
    }
    // editConfigurations call setConfigurations so it cause infinite loop if configurations be in the dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoneStep, environmentInput]);

  useEffect(() => {
    onIsScanOnDeploymentCheckedChange(isScanOnDeploymentChecked);
  }, [isScanOnDeploymentChecked, onIsScanOnDeploymentCheckedChange]);

  const handleEditEnvironmentClick = () => {
    if (!isEnvironmentInputDisabled && fetchedEnvironment) {
      setEnvironmentInput(fetchedEnvironment);
      editConfigurations(configurations, isScanOnDeploymentChecked);
    }
    setIsEditClicked((prevState) => !prevState);
    setIsEnvironmentInputDisabled((prevState) => !prevState);
  };

  const handleApplyToAllClick = () => {
    handleOverrideChange(ENVIRONMENT_CONFIGURATION_KEY, [environmentInput]);
    setIsEnvironmentInputDisabled(true);
    setIsEditClicked(false);
  };

  const shouldShowApplyButton = !!(!isEnvironmentInputDisabled && isEditClicked && isEnvironmentSet);

  const onChangeScanOnDeployment = (isChecked: boolean) => {
    setIsScanOnDeploymentChecked(isChecked);
    editConfigurations(configurations, isChecked);
    if (fetchedEnvironment) {
      setEnvironmentInput(fetchedEnvironment);
      setIsEnvironmentInputDisabled(true);
      setIsEditClicked(false);
      handleOverrideChange(ENVIRONMENT_CONFIGURATION_KEY, [fetchedEnvironment]);
    }
  };

  const scanOnDeploymentSubtitle = {
    text: 'pages.plan.configurationDialog.trigger.types.scanOnDeployment.subtitle',
    link: {
      href: i18n.t('pages.plan.configurationDialog.trigger.types.scanOnDeployment.linkHref'),
      text: 'pages.plan.configurationDialog.trigger.types.scanOnDeployment.linkText',
      icon: OpenInNewTab,
    },
  };

  return (
    <div>
      <ConfigurationsCheckboxField
        disabled
        isChecked
        label='pages.plan.configurationDialog.trigger.types.scanDaily'
        testid='dailyScan'
        tooltipCheckBox='pages.plan.configurationDialog.trigger.optionCannotBeTurnedOff'
      />

      <div className={styles.scanOnDeploymentCheckboxWrapper}>
        <ConfigurationsCheckboxField
          isChecked={isScanOnDeploymentChecked}
          label='pages.plan.configurationDialog.trigger.types.scanOnDeployment.title'
          onChange={onChangeScanOnDeployment}
          subtitle={scanOnDeploymentSubtitle}
          testid='scanOnDeployment'
        />
      </div>

      {isScanOnDeploymentChecked && (
        <div className={styles.inputSegment}>
          <JitText color={colors.lightGray} text='pages.plan.configurationDialog.trigger.fields.environmentName' />

          <TriggetConfigurationInput
            handleApplyToAllClick={handleApplyToAllClick}
            handleEditClick={handleEditEnvironmentClick}
            handleInputChange={handleEnvironmentInputChange}
            inputText={environmentInput}
            isInputDisabled={isEnvironmentInputDisabled}
            shouldShowApplyButton={shouldShowApplyButton}
            shouldShowEditIcon={!!fetchedEnvironment}
          />
        </div>
      )}

    </div>
  );
};
