import Skeleton from '@mui/material/Skeleton';
import { CSSProperties, FC } from 'react';

interface Props {
  className?: string;
  style?: CSSProperties;
}
export const LoadingSkeleton: FC<Props> = ({ className, style }) => (
  <Skeleton animation='wave' className={className} data-testid='skeleton' style={style} sx={{ transform: 'scale(1, 1)' }} />
);
