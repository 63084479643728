import { FC } from 'react';

import styles from './ResourceBreakdownGrid.module.scss';

import {
  ResourceBreakdownCard,
} from 'pages/Resources/ResourcesDashboardPage/components/ResourceBreakdownCard/ResourceBreakdownCard';
import { IAggregatedAssetStatistics } from 'types/interfaces';

interface Props {
  resourcesStatistic: IAggregatedAssetStatistics[];
}

export const ResourceBreakdownGrid: FC<Props> = ({ resourcesStatistic }) => (
  <div className={styles.grid}>
    {resourcesStatistic.map((resourceStatistic) => (
      <ResourceBreakdownCard key={resourceStatistic.aggregationKey} {...resourceStatistic} />
    ))}
  </div>
);
