import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { IManagePagination } from 'components/JitTable/hooks/useManagePagination';
import { ITableInstance } from 'components/JitTable/hooks/useManageTable';
import { IgnoreReason } from 'types/enums/IgnoreReason';
import { IFinding, TicketFinding } from 'types/interfaces';

interface IFindingTableContext {
  findings: IFinding[];
  setFindings: Dispatch<SetStateAction<IFinding[]>>;
  totalFindingsAmount: number | undefined;
  completeIgnoreFindings: (reason: IgnoreReason, description: string, ignoreByType?: boolean) => Promise<void>;
  isIgnoreLoading: boolean;
  resetSelectedFindings: () => void;
  onSetIsIgnoredToSelectedRows: (isIgnored: boolean) => void;
  onSelectRow: (row: IFinding) => void;
  tableInstance: ITableInstance;
  clearSelectedRow: () => void;
  paginationManager: IManagePagination;
  selectedRow: IFinding | null;
  limit?: number;
  onSetIgnoredToSingleRow: (findingToUpdate: IFinding, selectedIsIgnored: boolean) => void;
  setTicketUrlOnFindings: (findingsToUpdate: IFinding[], ticket: TicketFinding) => void;
  updateSelectedRow: (row: IFinding | null) => void;
  selectedFindingIds: string[];
  findingsToIgnore: IFinding[];
  setFindingsToIgnore: Dispatch<SetStateAction<IFinding[]>>;
  displayIgnoreByType: boolean;
  onDialogClose: () => void;
  isIgnoreFindingsDialogOpen: boolean;
}

export const FindingTableContext = createContext<IFindingTableContext>({
  findings: [],
  setFindings: () => {},
  totalFindingsAmount: undefined,
  completeIgnoreFindings: async () => {},
  isIgnoreLoading: false,
  resetSelectedFindings: () => {},
  onSetIsIgnoredToSelectedRows: () => {},
  onSelectRow: () => {},
  tableInstance: {} as ITableInstance,
  clearSelectedRow: () => {},
  paginationManager: {} as IManagePagination,
  selectedRow: null,
  onSetIgnoredToSingleRow: () => {},
  setTicketUrlOnFindings: () => {},
  updateSelectedRow: () => {},
  selectedFindingIds: [],
  findingsToIgnore: [],
  setFindingsToIgnore: () => {},
  displayIgnoreByType: false,
  onDialogClose: () => {},
  isIgnoreFindingsDialogOpen: false,
});

export const useFindingTableContext = () => useContext(FindingTableContext);
