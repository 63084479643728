import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './FindingsBreakdown.module.scss';

import { PriorityScore } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText, TextSize } from 'components/JitText/JitText';
import { BACKLOG_ROUTE } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import colors from 'themes/colors.module.scss';

interface Props {
  critical?: number;
  high?: number;
  medium?: number;
  low?: number;
  textSize?: TextSize;
  withLinks?: boolean;
}

interface Criticality {
  label: string;
  value: number;
  color: string;

}

export const FindingsBreakdown: FC<Props> = ({ critical, high, medium, low, textSize = 'xs', withLinks }) => {
  const navigate = useNavigate();
  const criticality: Criticality[] = [
    {
      label: 'Critical',
      value: critical,
      color: colors.criticalPriority,
    },
    {
      label: 'High',
      value: high,
      color: colors.highPriority,
    },
    {
      label: 'Medium',
      value: medium,
      color: colors.mediumPriority,
    },
    {
      label: 'Low',
      value: low,
      color: colors.lowPriority,
    },
  ].filter((crit) => crit.value !== undefined) as Criticality[];

  const renderItem = (item: Criticality, textColor: string = colors.white) => (
    <div key={item.label} className={styles.findingsBreakdownItem} data-testid='findingsBreakdownItem'>
      <JitIcon color={item.color} icon={PriorityScore} size={12} />

      <JitText bold color={textColor} size={textSize} text={item.value} />
    </div>
  );
  const navigateToBacklog = (item: Criticality) => navigate(`${BACKLOG_ROUTE}?resolution=OPEN&issue_severity=${item.label.toUpperCase()}&ignored=false`);

  const renderItemWithLink = (item: Criticality) => (
    <JitLink
      key={item.label}
      onClick={() => navigateToBacklog(item)}
      style={{
        textDecoration: 'none',
        cursor: 'pointer',
      }}
    >
      {renderItem(item, colors.lightBlue)}
    </JitLink>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.findingsBreakdown}>
        {criticality.map((item) => (
          withLinks ? renderItemWithLink(item) : renderItem(item)
        ))}
      </div>
    </div>
  );
};
