import { FC } from 'react';
import { useQuery } from 'react-query';

import { RisksBreadcrumbs } from '../components/RisksBreadcrumbs/RisksBreadcrumbs';

import { ResourceBreakdownGrid } from './components/ResourceBreakdownGrid/ResourceBreakdownGrid';
import { ResourcesDashboardTitle } from './components/Title/ResourcesDashboardTitle';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import styles from 'pages/Resources/ResourcesDashboardPage/ResourcesDashboardPage.module.scss';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';

export enum AssetAggregationKey {
  AssetType = 'asset_type',
  PriorityFactors = 'priority_factors',
}

export const ResourcesDashboardPage: FC = () => {
  const { getAssetsStatistics } = useAssetService();

  const { data: resourcesStatistics = [], isLoading, isError } = useQuery(
    [Queries.AssetsStatistics, AssetAggregationKey.PriorityFactors],
    getAssetsStatistics,
  );

  if (isLoading) {
    return <LoadingBar />;
  }

  if (isError) {
    // this is temporary error message, should be replaced with proper error component
    const message = 'Failed to fetch assets statistics';
    return <div className={styles.errorMessage}>{message}</div>;
  }

  const totalCount = resourcesStatistics?.reduce((acc, resource) => acc + resource.count, 0) || 0;

  return (
    <div className={styles.wrapper}>
      <RisksBreadcrumbs />

      <ResourcesDashboardTitle resourcesCount={totalCount} />

      <ResourceBreakdownGrid resourcesStatistic={resourcesStatistics} />
    </div>
  );
};
