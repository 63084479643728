/* eslint-disable react/jsx-max-depth */
import { t } from 'i18next';
import { debounce } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import commonStyles from '../common.module.scss';

import styles from './ChooseGitlabProjects.module.scss';

import { GitlabIcon } from 'assets';
import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitRadioButton } from 'components/JitRadioButton/JitRadioButton';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { JitText } from 'components/JitText/JitText';
import { IGroup, IProject, useGitlabService } from 'services/GitlabService/useGitlabService';
import { Queries } from 'types/enums/Queries';

interface Props {
  onContinueButtonClick: () => void;
  isAllSelected: boolean;
  setIsAllSelected: (value: boolean) => void;
  selectedProjects: IProject[];
  isUpdateInstallationLoading: boolean;
  setSelectedProjects: (value: IProject[]) => void;
  selectedGroup?: IGroup;
}

const PAGE_LIMIT = 100;

const NO_SEARCH_TEXT = t('GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.noSearchText');
const SEARCH_BOX_PLACEHOLDER = t`GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.searchBoxPlaceholder`;

export const ChooseGitlabProjects: FC<Props> = ({
  onContinueButtonClick,
  isAllSelected,
  setIsAllSelected,
  selectedProjects,
  isUpdateInstallationLoading,
  setSelectedProjects,
  selectedGroup,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [noOptionsText, setNoOptionsText] = useState<string>('');

  const { fetchProjects } = useGitlabService();

  const {
    data,
    isLoading: isProjectsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    remove,
  } = useInfiniteQuery(
    [Queries.GitlabProjects, selectedGroup?.id, searchTerm],
    ({ pageParam = 1 }) => fetchProjects(selectedGroup!.id, {
      page: pageParam,
      per_page: PAGE_LIMIT,
      search: searchTerm,
    }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.length < PAGE_LIMIT) return undefined;
        return pages.length + 1;
      },
      enabled: !!selectedGroup?.id,
    },
  );

  const projectOptions = useMemo(
    () => data?.pages.flat().map((project) => ({ ...project,
      path_with_namespace: project?.path_with_namespace.split('/').slice(1).join('/') })).sort((a, b) => (a?.path_with_namespace ?? '').localeCompare(b?.path_with_namespace ?? '')) ?? [],
    [data],
  );

  const onScroll = useCallback((e) => {
    if (hasNextPage && !isFetchingNextPage && calcShouldFetchMore(e, 20)) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const debouncedInputChange = debounce((value: string) => {
    remove();

    setSearchTerm(value);
  }, 300);

  const handleInputChange = useCallback((value: string) => {
    if (value.length < 3 && value.length > 0) {
      if (!noOptionsText) setNoOptionsText(NO_SEARCH_TEXT);
      return;
    }

    if (noOptionsText) setNoOptionsText('');

    debouncedInputChange(value);
  }, [noOptionsText, debouncedInputChange]);

  return (
    <DialogContentWrapper
      actionButtons={[
        {
          label: 'GitlabIntegrationWizard.chooseGitlabProjects.buttonText',
          disabled: !isAllSelected && selectedProjects.length === 0,
          handleClick: onContinueButtonClick,
          isLoading: isUpdateInstallationLoading,
        },
      ]}
      icon={GitlabIcon}
    >
      <div className={`${commonStyles.dotJitTextBox} ${styles.content}`}>
        <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.title' />

        <div className={styles.radioButtonsContainer}>
          <div
            className={styles.buttonContainer}
            onClick={() => setIsAllSelected(true)}
            role='button'
            tabIndex={0}
          >
            <JitRadioButton checked={isAllSelected} />

            <div className={styles.buttonWrapper}>
              <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.all.label' />

              <JitText muted size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.all.description' />
            </div>
          </div>

          <div
            className={styles.buttonContainer}
            onClick={() => setIsAllSelected(false)}
            role='button'
            tabIndex={0}
          >
            <JitRadioButton checked={!isAllSelected} />

            <div className={styles.buttonWrapper}>
              <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.label' />

              <JitText muted size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.description' />

              {!isAllSelected && (
              <div className={styles.dropdownContainer}>
                <JitAutoCompleteForm
                  clearOnBlur={false}
                  disableCloseOnSelect
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.path_with_namespace}
                  isLoading={isProjectsLoading || isFetchingNextPage}
                  noOptionsText={noOptionsText}
                  onInputChange={handleInputChange}
                  onScroll={onScroll}
                  options={projectOptions}
                  placeHolder={SEARCH_BOX_PLACEHOLDER}
                  selectedValue={selectedProjects}
                  setSelectedValue={setSelectedProjects}
                />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DialogContentWrapper>
  );
};
