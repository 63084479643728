import { FC, useCallback, useState } from 'react';

import { PlanItemManualDialog } from 'components/JitDialogs/PlanItemManualDialog/PlanItemManualDialog';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { useUpdatePlanItem } from 'context/PlansContext/hooks/useUpdatePlanItem';
import { IPlanItemDetails, IPlanItemRequirements } from 'types/interfaces';

interface Props {
  planSlug: string;
  planItem?: IPlanItemDetails;
  isOpen: boolean;
  onClose: () => void;
}

export const PlanItemManual: FC<Props> = ({ planSlug, planItem, isOpen, onClose }) => {
  const { updatePlanItem } = useUpdatePlanItem();
  const { activatePlanItem } = useActivatePlanItem();
  const [isInputUpdating, setIsInputUpdating] = useState<boolean>(false);

  const activateAndUpdateItem = useCallback((status, note, requirements) => {
    const input = {
      status,
      note,
      requirements,
    };
    if (planItem) {
      const updatedPlanItem: IPlanItemDetails = {
        ...planItem,
        status_properties: undefined,
        input,
      };
      updatePlanItem(planSlug, updatedPlanItem);
      activatePlanItem(planSlug, updatedPlanItem);
    }
  }, [planItem, updatePlanItem, planSlug, activatePlanItem]);

  const onConfigChange = useCallback(async (config: { status?: string; note: string; requirements?: IPlanItemRequirements }) => {
    const { status: newStatus, note: newNote, requirements: newRequirements } = config;

    setIsInputUpdating(true);
    activateAndUpdateItem(newStatus, newNote, newRequirements);
    onClose();
    setIsInputUpdating(false);
  }, [activateAndUpdateItem, onClose]);

  return (
    <PlanItemManualDialog
      config={{
        status: planItem?.input?.status,
        note: planItem?.input?.note,
        requirements: planItem?.input?.requirements,
      }}
      description={planItem?.description || ''}
      isApplyLoading={isInputUpdating}
      isOpen={isOpen}
      onClose={onClose}
      onConfigChange={onConfigChange}
      requirementsTemplate={planItem?.requirements}
      title={planItem?.name || ''}
    />
  );
};
