import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { PERMISSIONS } from '../constants';

import { getDefaultRoute } from './getDefaultRoute';
import { buildRoutes } from './routeBuilder';
import { buildRoutesPermissions } from './routePermissionsBuilder';
import { getUiLayoutMode, UiLayoutMode } from './uiLayoutMode';
import { useHasPermissions } from './useHasPermissions';

import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { constants } from 'globalConstants';
import { Layout } from 'wrappers/Layout/Layout';
import { TeamsPortalLayout } from 'wrappers/TeamsPortalLayout/TeamsPortalLayout';

export const useRoutesOfUser = () => {
  const { viewedWelcomeTeamsPage, userPermissions, userHasAccessToManagementPortal, setViewedWelcomeTeamsPage } = useAuthContext();
  const { uiShowSbomPage, uiShowTheLoopPage, uiShowSecurityChampionQuickGuide, uiShowResourcesPage } = useFlags();
  const {
    hasTriedFetchInstallations,
    isAnyScmVendorIntegrated,
    initialOnboardingCompleted,
  } = useTenantContext();
  const { hasPermission } = useHasPermissions();
  const { pathname } = useLocation();

  const uiLayoutMode = useMemo(() => getUiLayoutMode({
    currentRoute: pathname,
    userPermissions,
  }), [pathname, userPermissions]);
  const routesComponents = useMemo(() => buildRoutes(
    userPermissions,
    uiShowSbomPage,
    uiShowTheLoopPage,
    viewedWelcomeTeamsPage,
    uiShowSecurityChampionQuickGuide,
    initialOnboardingCompleted,
    uiShowResourcesPage,
  ), [userPermissions, uiShowSbomPage, uiShowTheLoopPage, viewedWelcomeTeamsPage, uiShowSecurityChampionQuickGuide, initialOnboardingCompleted, uiShowResourcesPage]);

  const defaultRoute = useMemo(() => getDefaultRoute({
    uiLayoutMode,
    viewedWelcomeTeamsPage,
    hasTriedFetchInstallations,
    isAnyScmVendorIntegrated,
    routesComponents,
    initialOnboardingCompleted,
    uiShowSecurityChampionQuickGuide,
  }), [
    uiLayoutMode,
    viewedWelcomeTeamsPage,
    hasTriedFetchInstallations,
    isAnyScmVendorIntegrated,
    routesComponents,
    initialOnboardingCompleted,
    uiShowSecurityChampionQuickGuide,
  ]);
  const componentToPathMap = useMemo(() => {
    const map = new Map();
    Object.entries(routesComponents).forEach(([path, comp]: [string, JSX.Element]) => {
      map.set(comp.type, path);
    });
    return map;
  }, [routesComponents]);

  const getPathByComponent = useCallback((component: JSX.Element) => {
    const path = componentToPathMap.get(component.type);
    return path || `/${constants.routes.OVERVIEW}`;
  }, [componentToPathMap]);

  const routesPermissions = useMemo(() => buildRoutesPermissions(userPermissions), [userPermissions]);

  // Update the default route according to the defaultRoute component
  routesComponents['*'] = defaultRoute.component;
  routesPermissions['*'] = routesPermissions[getPathByComponent(defaultRoute.component)] || PERMISSIONS.READ_OVERVIEW;
  // filter out the routes that the user doesn't have permission to access
  const routesWithPermissions = useMemo(
    () => Object.entries(routesPermissions).filter(([route, permissionKey]) => (hasPermission(permissionKey) || route === '*')
      && route in routesComponents),
    [hasPermission, routesComponents, routesPermissions],
  );

  const isWelcomeView = useMemo(() => {
    if (!viewedWelcomeTeamsPage && userHasAccessToManagementPortal) {
      // At the moment, we assume that if the user has permission to the management portal,
      // they are a strong user and came to the teams view from a different route. In this case, we disable the welcome view and treat it as if
      // the user has already seen it.
      setViewedWelcomeTeamsPage(true);
      return false;
    }
    return !viewedWelcomeTeamsPage;
  }, [viewedWelcomeTeamsPage, userHasAccessToManagementPortal, setViewedWelcomeTeamsPage]);

  const Wrapper = useMemo(() => {
    if (uiLayoutMode === UiLayoutMode.TeamPortal) {
      return <TeamsPortalLayout isWelcomeView={isWelcomeView} />;
    }
    return <Layout />;
  }, [isWelcomeView, uiLayoutMode]);

  const routesToRender = (
    <Route element={Wrapper}>
      {routesWithPermissions.map(([route]) => (
        <Route
          key={route}
          element={routesComponents[route]}
          path={route}
        />
      ))}
    </Route>
  );

  return {
    routesToRender,
    defaultRoute,
  };
};
