import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { RisksBreadcrumbs } from '../components/RisksBreadcrumbs/RisksBreadcrumbs';
import { getAssetDisplayName } from '../utils/getAssetDisplayName';

import { FindingsTableWrapper } from './components/FindingsTableWrapper/FindingsTableWrapper';
import styles from './ResourcePage.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { PriorityFactorsGraph } from 'components/PriorityFactorsGraph/PriorityFactorsGraph';
import { useAssetsContext } from 'context/AssetsContext';
import { GraphProvider } from 'context/GraphContext/GraphProvider';
import { constants } from 'globalConstants';

export const ResourcePage: FC = () => {
  const { assets } = useAssetsContext();
  const assetId = useParams()[constants.routes.pathParameters.RESOURCE_ID];

  const asset = useMemo(
    () => assets.find((a) => a.asset_id === assetId),
    [assets, assetId],
  );

  const pageTitle = asset ? getAssetDisplayName(asset) : '';

  return (
    <div className={styles.wrapper}>
      <RisksBreadcrumbs asset={asset} />

      <div className={styles.titleWrapper}>
        <PageTitles title={pageTitle} />
      </div>

      <div className={styles.bodyWrapper}>
        <div className={styles.graphWrapper}>
          <JitText size='m' text='pages.risks.resources.resourcePage.graphTitle' />

          <GraphProvider>
            <PriorityFactorsGraph entity={asset} />
          </GraphProvider>
        </div>

        <FindingsTableWrapper asset={asset} />
      </div>
    </div>
  );
};
