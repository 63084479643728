import ELK, { ElkNode } from 'elkjs';
import { Node } from 'reactflow';

import { CustomEdge } from '../CustomEdge';
import { CustomNode } from '../CustomNode';
import { GraphEntity, CustomNodeType, CustomNodeData, CustomEdgeType } from '../types';

import { iconsMap } from './iconsMap';

import { IEntityGraphEdge, IEntityGraphNode } from 'types/interfaces/Graph/IEntityGraph';

export const NODE_HEIGHT = 50;
export const EXTRA_HEIGHT_NODE_WITH_FACTOR = 25;
export const NODE_WIDTH = 200;

export const buildEdge = (edge: IEntityGraphEdge): CustomEdgeType => ({
  ...edge,
  data: {
    description: edge.description,
  },
  type: 'customEdge',
  style: {
    stroke: '#0079f7',
    strokeWidth: 2,
    opacity: 0.5,
  },
});
export const buildNode = (node: IEntityGraphNode, entity: GraphEntity): CustomNodeType => ({
  ...node,
  type: 'customNode',
  draggable: false,
  data: {
    ...node,
    entity,
    icon: iconsMap[node.type as keyof typeof iconsMap] || {
      icon: () => null,
      bgColor: 'gray',
    },
    metadata: node.metadata,
  },
  position: {
    // The position is calculated by the layout algorithm, so just a placeholder
    x: 0,
    y: 0,
  },
  height: NODE_HEIGHT + EXTRA_HEIGHT_NODE_WITH_FACTOR * (node?.factors?.length || 0),
  width: NODE_WIDTH,
});

export const nodeTypes = {
  customNode: CustomNode,
};

export const edgeTypes = {
  customEdge: CustomEdge,
};
export const getLayoutedElements = async (
  nodes: CustomNodeType[],
  edges: CustomEdgeType[],
): Promise<{
  nodes: CustomNodeType[];
  edges: CustomEdgeType[];
}> => {
  const elk = new ELK();
  const layout = await elk.layout({
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered', // Use 'layered' for hierarchical layout
      'elk.direction': 'RIGHT', // Direction of the layout, can be 'RIGHT', 'DOWN', 'UP', or 'LEFT'
      'elk.spacing.nodeNode': '20', // Spacing between nodes
      'elk.layered.spacing.nodeNodeBetweenLayers': '100', // Spacing between different layers
      'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX', // Strategy for node placement
    },
    children: nodes as ElkNode[],
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  });

  const layoutedNodes = layout.children!.map((node: ElkNode) => ({
    ...node,
    position: {
      x: node.x! - NODE_HEIGHT / 2,
      y: node.y! - NODE_WIDTH / 2,
    },
  })) as Node<CustomNodeData>[];

  return {
    nodes: layoutedNodes,
    edges,
  };
};
