import colors from 'themes/colors.module.scss';
import { hexColorToRGBA } from 'utils';

export const rowBackgroundColorVariantMap = {
  dark: colors.cards04,
  bright: colors.cards04,
  light: hexColorToRGBA(colors.welcomePageBackground, 0.8),
};

export const borderColorVarianMap = {
  dark: colors.cards02,
  bright: colors.cards,
  light: hexColorToRGBA(colors.welcomePageBackground, 0.8),
};

export const hoveredBorderColorVarianMap = {
  dark: colors.blue02,
  bright: colors.blue02,
  light: colors.blue02,
};

export const borderRadiusVariantMap = {
  dark: 8,
  bright: 8,
  light: 14,
};
