import { FC } from 'react';

import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import { BaseCard } from 'pages/Resources/ResourcesPage/component/BaseCard/BaseCard';

interface Props {
  score: number;
}

export const ScoreCard: FC<Props> = ({ score }) => (
  <BaseCard title='pages.risks.resources.resourcesPage.cards.score.description'>
    <PriorityBox priorityScore={score} size='xl' withBorder={false} />
  </BaseCard>
);
