import { FC } from 'react';

import styles from './Header.module.scss';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { FindingsCountCard } from 'pages/Resources/ResourcesPage/component/FindingsCountCard/FindingsCountCard';
import { ScoreCard } from 'pages/Resources/ResourcesPage/component/ScoreCard/ScoreCard';

export const Header: FC = () => (
  <div className={styles.header}>

    <PageTitles title='pages.risks.resources.resourcesPage.title' />

    <div className={styles.cardsWrapper}>
      <ScoreCard score={90} />

      <FindingsCountCard critical={131} high={92} medium={30} />
    </div>
  </div>
);
