import { FC } from 'react';

import { Row } from '../Row/Row';

import { JitTag } from 'components/JitTag/JitTag';
import colors from 'themes/colors.module.scss';

interface Props {
  tenantName: string;
  shouldShowArrow?: boolean;
  isLast: boolean;
  onClick: () => void;
  backgroundColor?: string;
  shouldShowName?: boolean;
  endAdornment?: JSX.Element;
  shouldHandleHover?: boolean;
}

export const TenantRow: FC<Props> = ({
  tenantName, shouldShowArrow = true, isLast, onClick, backgroundColor, shouldShowName = true, endAdornment, shouldHandleHover,
}) => (
  <Row
    bgColor={backgroundColor}
    endAdornment={endAdornment}
    icon={<JitTag backgroundColor={colors.navbarTag} bold={false} color={colors.white} padding='10px 16px' text={tenantName.slice(0, 1).toUpperCase() || ''} textSize='s' />}
    isLast={isLast}
    onSelect={() => onClick()}
    shouldHandleHover={shouldHandleHover}
    shouldShowArrow={shouldShowArrow}
    shouldShowName={shouldShowName}
    text={tenantName}
  />
);
