import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';

import { ConfigurableRow } from 'components/SecretsManagementDialog/Components/ConfigurableRow';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useGetAreSettingsProperlySet } from 'context/ConfigurationsContext/hooks/useGetAreSettingsProperlySet';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { IConfigurations, ZapAuthenticationConfigType } from 'types/interfaces';
import { IConfigurableItem } from 'types/interfaces/Configurations/IConfigurableItem';

interface Props {
  type: ZapAuthenticationConfigType;
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
  planItemSlug: string;
  onChangeConfiguration: Dispatch<SetStateAction<IConfigurations>>;
}

// onChangeConfiguration will be used in the handleSaveApplication func which will be implemented in a later stage
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConfigurationsZap: FC<Props> = ({ type, setIsDoneStep, planItemSlug, onChangeConfiguration }) => {
  const { plans } = usePlansContext();
  const { configurations } = useConfigurationsContext();
  const { getAreSettingsProperlySet } = useGetAreSettingsProperlySet();

  const isDeleteDisabled: boolean = useMemo(() => configurations.applications?.length === 1, [configurations]);
  const deleteTooltip = isDeleteDisabled ? 'configurations.zap.minimumApplicationsTooltip' : undefined;

  const zapApplications = useMemo(() => configurations.applications?.filter((app) => app.type === type) || [], [configurations, type]);
  const planItem = useMemo(() => {
    const foundPlan = Object.values(plans).find((plan) => plan.items && plan.items[planItemSlug]);
    return foundPlan ? foundPlan.items?.[planItemSlug] : undefined;
  }, [plans, planItemSlug]);

  const isMissingConfig = useMemo(() => planItem && !getAreSettingsProperlySet(planItem), [planItem, getAreSettingsProperlySet]);

  useEffect(() => {
    setIsDoneStep(!isMissingConfig);
  }, [isMissingConfig, setIsDoneStep]);

  const configurableItems: IConfigurableItem[] = useMemo(() => zapApplications.map((config) => ({
    name: config.application_name,
  })), [zapApplications]);

  const handleDeleteConfiguration = (item: IConfigurableItem) => {
    // eslint-disable-next-line no-console
    console.log('handleDeleteConfiguration placeholder, called with:', item);
  };

  const handleEditConfiguration = (item: IConfigurableItem) => {
    // eslint-disable-next-line no-console
    console.log('handleEditConfiguration placeholder, called with:', item);
  };

  return (
    <div data-testid='application-rows'>
      {configurableItems.map((item) => (
        <ConfigurableRow
          key={item.name}
          configurableItem={item}
          disableDelete={isDeleteDisabled}
          disableDeleteTooltip={deleteTooltip}
          onDelete={() => handleDeleteConfiguration(item)}
          onEdit={() => handleEditConfiguration(item)}
        />
      ))}
    </div>

  );
};
