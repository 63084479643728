import { Divider } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { ActivateAllButton } from '../ActivateAllButton/ActivateAllButton';

import styles from './PlanCategoryListTitle.module.scss';

import { JitFilters } from 'components/JitFilters/JitFilters';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import colors from 'themes/colors.module.scss';
import { IFilter, IPlanItemDetails } from 'types/interfaces';

interface Props {
  filters: IFilter[];
  updateFilters: (filters: IFilter[]) => void;
  resetFilters: () => void;
  planItems: IPlanItemDetails[];
}

export const PlanCategoryListTitle: FC<Props> = ({ filters, updateFilters, resetFilters, planItems }) => {
  const { centralizedRepo } = useAssetsContext();
  const { uiShowActivateAllPlanItemsButton } = useFlags();
  const { isAnyScmVendorIntegrated } = useTenantContext();

  const updateFilter = (e: IFilter) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.entityKey === e.entityKey) {
        return {
          ...filter,
          selectedValue: e.selectedValue,
        };
      }
      return filter;
    });

    updateFilters(updatedFilters);
  };

  return (
    <>
      <div className={styles.title}>
        <div className={styles.titleText}>
          <div className={styles.securityRequirementsText}>
            <JitText bold text='pages.plan.listTitle.AvailableSecurityControls' />
          </div>

          {centralizedRepo && (
            <JitLink
              data-testid='planAsCodeLink'
              href={config.githubJitPlan(centralizedRepo.owner, centralizedRepo.asset_name)}
              rel='noreferrer'
              target='_blank'
              underline='none'
            >
              <JitText color={colors.blue02} text='pages.plan.topRow.planAsCode' />
            </JitLink>
          )}
        </div>

        {uiShowActivateAllPlanItemsButton && (
          <ActivateAllButton planItems={planItems} />
        )}

      </div>

      {isAnyScmVendorIntegrated && (
        <>
          <Divider
            className={styles.divider}
            sx={{
              margin: '0.5rem 0',
            }}
          />

          <div data-testid='planCategoryListFilters'>
            <JitFilters
              filters={filters}
              resetFilters={resetFilters}
              showFilterIcon={false}
              updateFilter={updateFilter}
            />
          </div>
        </>
      )}
    </>
  );
};
