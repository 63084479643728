import { FC } from 'react';

import { OverviewBody } from './components';
import styles from './OverviewPage.module.scss';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { useAuthContext } from 'context/AuthContext/AuthContext';

export const OverviewPage: FC = () => {
  const { frontEggUser } = useAuthContext();
  return (
    <div className={styles.wrapper} data-testid='overviewPageWrapper'>
      <div className={styles.header}>
        <PageTitles
          subtitle={{
            text: 'pages.overview.subtitle',
            components: {
              name: frontEggUser.name,
            },
          }}
          title='pages.overview.title'
        />
      </div>

      <div className={styles.body}>
        <OverviewBody />
      </div>

    </div>
  );
};
