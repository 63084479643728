import { FC, useEffect, useMemo } from 'react';

import styles from './SecurityPlanStatus.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitText } from 'components/JitText/JitText';
import { usePlansContext } from 'context/PlansContext';
import { ScoreGauge } from 'pages/SecurityPlansPage/components/ScoreGauge/ScoreGauge';
import colors from 'themes/colors.module.scss';

const PLAN_SLUG = 'plan-application-security';

export const SecurityPlanStatus: FC = () => {
  const { plans, fetchPlans, hasFetchedPlans, isFetchingPlans } = usePlansContext();

  useEffect(() => {
    if (!hasFetchedPlans || isFetchingPlans) fetchPlans();
  }, [fetchPlans, hasFetchedPlans, isFetchingPlans]);

  const score = useMemo(() => {
    const plan = plans[PLAN_SLUG];
    if (!plan) return 0;

    return plan.activeItemSlugs.size / plan.allItemSlugs.size;
  }, [plans]);

  return (
    <div className={styles.root}>
      {isFetchingPlans ? (
        <CircularLoadingSpinner />
      ) : (
        <>
          <ScoreGauge backgroundColor={colors.lightGray} score={score} variant='small' />

          <JitText bold muted text='pages.quickStart.checks.securityPlansProcess.statusLabel' />
        </>
      )}
    </div>
  );
};

