import { FC } from 'react';

import styles from './LeaderboardTable.module.scss';
import { useGetColumns } from './useGetColumns';
import { addCurrentTeamIfNotInTop10, assignRanksToTeams } from './utils/utils';

import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { useGetScoreTrendingByWeek } from 'components/ScoreTrendingWidget/hooks/useGetScoreTrendingByWeek';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';

interface Props {
  teams: IGetTeamResponseItem[];
  currentTeam?: IGetTeamResponseItem;
}

export const LeaderboardTable: FC<Props> = ({ teams, currentTeam }) => {
  let teamsWithRank: IGetTeamResponseItem[] = assignRanksToTeams(teams);
  teamsWithRank = addCurrentTeamIfNotInTop10(teamsWithRank, currentTeam);
  const teamIds = teamsWithRank.map((team) => team.id);
  const { scoreTrending, isLoading } = useGetScoreTrendingByWeek(teamIds);

  teamsWithRank = teamsWithRank.map((team) => ({ ...team,
    scoreTrending: isLoading ? null : scoreTrending?.find((score) => score.entityId === team.id) }));

  const columns = useGetColumns();
  const tableInstance = useGetTableInstance(columns, teamsWithRank);

  return (
    <div className={styles.tableWrapper} data-testid='leaderboard-table'>
      <JitTable
        cellPadding='30px 0px 30px 40px'
        headerCellPadding='16px 0px 16px 40px'
        isLoading={false}
        shouldDisplayWithPaper={false}
        showPagination={false}
        tableDesign='light'
        tableInstance={tableInstance}
      />
    </div>
  );
};
