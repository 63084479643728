import { styled } from '@mui/material/styles';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { CSSProperties } from 'react';

import { rowBackgroundColorVariantMap, borderColorVarianMap, hoveredBorderColorVarianMap, borderRadiusVariantMap } from './utils/constants';

import { CellVerticalAlign, DefaultCellVerticalAlign, DefaultTableDesign, TableDesignVariants } from 'components/JitTable/constants';
import colors from 'themes/colors.module.scss';

interface RowProps extends TableRowProps {
  isselected?: string;
  style?: CSSProperties;
  hoverStyle?: CSSProperties;
  verticalalign?: CellVerticalAlign;
  tableDesign?: TableDesignVariants;
  isClickable: boolean;
}

export const StyledTableRow = styled(TableRow)<RowProps>(({ isselected, style, verticalalign = DefaultCellVerticalAlign, tableDesign = DefaultTableDesign, hoverStyle, isClickable }) => ({
  backgroundColor: rowBackgroundColorVariantMap[tableDesign],
  height: 70,
  borderColor: borderColorVarianMap[tableDesign],
  borderWidth: isClickable ? '1px 0' : '0px 0px',
  borderStyle: 'solid',
  position: 'relative',

  ...(isselected && {
    borderColor: colors.blue02,
  }),
  '&:hover': {
    borderColor: hoveredBorderColorVarianMap[tableDesign],
    ...hoverStyle,
  },
  ...(style || {}),

  td: {
    borderWidth: 'inherit',
    borderColor: 'inherit',
    verticalAlign: verticalalign,
    borderStyle: 'solid',

    '&:first-of-type': {
      borderTopLeftRadius: borderRadiusVariantMap[tableDesign],
      borderBottomLeftRadius: borderRadiusVariantMap[tableDesign],
      borderRightWidth: 0,
      borderLeftWidth: isClickable && 1,
    },

    '&:last-of-type': {
      borderTopRightRadius: borderRadiusVariantMap[tableDesign],
      borderBottomRightRadius: borderRadiusVariantMap[tableDesign],
      borderRightWidth: isClickable && 1,
      borderLeftWidth: 0,
    },
  },
}));
